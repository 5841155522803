import { FormGroup, FormControl,FormArray,ValidationErrors } from '@angular/forms';
export class GroupPruefArray {
    name: string
    FormGroup: FormGroup
    FormControlNames : string[]
    FormControlErrors: ValidationErrors[]

    constructor() {
        this.FormGroup = new FormGroup({})
        this.FormControlNames = new Array()
        this.FormControlErrors = new Array()
    }
}