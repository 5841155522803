import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map'
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject} from "rxjs/Rx";


@Injectable()
export class AuthenticationService {
    cookies: Object;
    cookieValue: Object;
    public loggedin :boolean;
    public loggedinBhSubj : BehaviorSubject<boolean>;
    private user1 : any;
    
    constructor(private http: HttpClient, private cookieService: CookieService){
        this.user1 = localStorage.getItem('currentUser')
        if(this.user1!=null){
            if (this.user1.username !=''){
                this.loggedin = true;
            }else{
                this.loggedin = false;  
            }    
        }else{
            this.loggedin=false;
        }    
        this.loggedinBhSubj = new BehaviorSubject<boolean>(this.loggedin)
        console.log("set loggind in authservice:", this.loggedin)
    }
    ngOnInit(){
    }

    login(username: string, password: string) {
        //erstmal alle Cookies löschen, 
        //nicht dass man mit einem alten Cookie zum Server kommt
        //wäre einfacher, wenn man in golang das einfach auf Session Cookies gesetzt hätte...
        //alle Cookies löschen:
        this.cookies=this.cookieService.getAll()
        console.log("COOKIES: ", this.cookies)
        this.cookieService.deleteAll();
        this.cookieService.deleteAll('/ ',  '/' );
        this.cookieService.deleteAll('/ ');
        this.cookieService.deleteAll('./ ');
        this.cookieService.deleteAll('/');
        this.cookieService.deleteAll('./' );
        this.cookieService.deleteAll('../')

        return this.http.post<any>("https://www.quickcheckumwelt.de/go/login/", { username: username, password: password })
            .map(
                user => {
                    if(user.username){
                        //success, weil Server mit Username und Passwort geantwortet hat !
                        console.log("successfull logged in: " + user.username)
                        localStorage.setItem('currentUser', JSON.stringify(user)); 
                        this.loggedin=true
                        this.loggedinBhSubj.next(this.loggedin)                   
                        
                    }
                    return user
                },
                Error => { 
                    console.log("Login Error: ", Error)
                    return Error
                }                             
            );    
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.loggedin=false
        this.loggedinBhSubj.next(this.loggedin)
        this.http.post(
            "https://www.quickcheckumwelt.de/go/logout/",[]
        )
        .subscribe(
            (response)=>console.log(response),
            (error)=>{
                console.log("Logout",error)
            }
        )        
    }
}