import { Component, OnInit, EventEmitter,Input } from '@angular/core';
import { DataService } from '../_services/data.service'


@Component({
  selector: 'app-abgabe',
  templateUrl: './abgabe.component.html',
  styleUrls: ['./abgabe.component.css']
})
export class AbgabeComponent implements OnInit {

  @Input() abgabeQuickcheckUmwelt : EventEmitter<void>;
  
  constructor(private Dataservice : DataService) { }

  ngOnInit() {
        //Listen to saveBetriebsdaten-Event vom Parentcomponent: eingabe.component => SAVE local Data!
        this.abgabeQuickcheckUmwelt.subscribe(
          ()=>{
            //lokal: schreibe die Werte in die Tonnen, dann in den Dataservice -
            this.Dataservice.abgabeQuickcheckUmwelt()},
          err=>{console.log(err)},
          complete=>{console.log("abgabeQuickcheckUmwelt vom Frontent versendet")}
        )
  }
}
