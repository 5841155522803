import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../_services/data.service'
import { AlertService, AuthenticationService } from '../_services/index';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private DataService : DataService) { }
        

    ngOnInit() {
        // get return url from route parameters or default to '/'
        this.returnUrl = "/eingabe"
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/eingabe';
    }

    login() {
        this.loading = true;
        this.authenticationService.login(this.model.username, this.model.password)
            .subscribe(
                data => {
                    this.loading = false;
                    this.router.navigate([this.returnUrl]);                    
                },
                Error => {
                    if (Error!=null){
                        this.alertService.error(Error);
                    }else{this.alertService.error("Bitte kontaktieren Sie Viabono. Es ist etwas schief gelaufen.")}
                    
                    console.log(Error)
                    this.loading = false;
                });
    }
}
