import { Adresse } from '../_models/adresse'

export class Adressen{
    Rechnungsadresse: Adresse;
    Firmenadresse: Adresse;

    constructor() {
        this.Firmenadresse = new Adresse()
        this.Rechnungsadresse = new Adresse()
    }
}
