import {nullString} from "../_models/index"

export class Adresse {
    adress_id    : number         
	typ         : string      
	name        : String 
	adresszusatz: String 
	strasse     : string      
	ort         : string      
	postleitzahl: string      
	land        : string
	UStID 		: string

	constructor(){
		this.adress_id    = 0        
		this.typ          = ""     
		this.name         = ""
		this.adresszusatz = ""
		this.strasse      = ""     
		this.ort          = ""     
		this.postleitzahl = ""     
		this.land         = ""	
	}

}

