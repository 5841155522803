import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import {MatRadioModule} from '@angular/material/radio';
import { PruefService } from '../_services/pruefservice.service';
import { DataService } from '../_services/data.service';
import * as Rx from "rxjs";
import { AlertService } from '../_services/index';

@Component({
  selector: 'app-pruefung',
  templateUrl: './pruefung.component.html',
  styleUrls: ['./pruefung.component.css']
})
export class PruefungComponent implements OnInit {  
  @Input() pruefeEingabe : EventEmitter<void>;
  @Input() pruefungQuickcheckUmwelt : EventEmitter<void>;

  //abgabeKnopfGesperrt ist die Abgaben, wenn noch Fehler im Prüfservice vorhanden sind.
  //abgabeKnopfGesperrt wird auch immer wieder auf true gesetz, wenn wieder Fehler eingeabut werden
  abgabeKnopfGesperrt : boolean
  
  //gibt an, ob die Kaufbedingungen angeommen wurden
  //wird immer wieder auf true gesetzt, wenn der QU abgegeben wurde
  valueAGBacceptance:boolean

  disabledAGBacceptance: boolean
  //
  DisabledWegenPruefehlern:boolean
  
  error:string
  
  value:number
  
  //gibt an, ob der QU schon abgeben wurde
  berechnet:boolean


  FormGroupNamen: string[]
  Errors : string[][]

  umweltcheckSubscription: Rx.Subscription;

  constructor(private Pruefservice : PruefService,private DataService : DataService,private alertService: AlertService) { 
    this.valueAGBacceptance=false
    this.abgabeKnopfGesperrt=true
    this.DisabledWegenPruefehlern=true
    this.FormGroupNamen = new Array()
    this.Errors = new Array
    this.value=1
    this.berechnet = false
    setTimeout(()=>{
      this.berechnet=this.DataService.Umweltchecks.Umweltchecks[this.DataService.NummerUmweltcheck].berechnet
      console.log("Pruefservice - berechnet:", this.berechnet)
      //wenn der QU schonberechnet wurde, dann:
      if (this.berechnet==true){
        this.abgabeKnopfGesperrt=false

        //setzt mir : DisabledWegenPruefehlern
        //Reihenfolge so entscheidend!
        this.updateErrors()
      
        this.valueAGBacceptance=true
        this.disabledAGBacceptance=true
    }
    },3000)
  }

  ngOnInit() {
    this.Pruefservice.InvalidFormGroupOberservable.subscribe(()=>{
      this.updateErrors()
    })
  }
  updateErrors(){
    //was brauch ich denn?
    //dasselbe, was im Pruefservice schon steht...die Groups und die Controls als Arrays 
    //zum durchlaufen:
    console.log("pruefcomponent: udpateErros/refresh Erros")
    this.FormGroupNamen.length=0
    this.Errors.length=0

    let i : number = 0
    for (let InvalidFormgroup of this.Pruefservice.InvalidFormGroups){       
      this.FormGroupNamen.push(InvalidFormgroup.name)
      this.Errors[i] = new Array()
      this.Errors[i]=InvalidFormgroup.FormControlNames
      i++
    }
    //wenn keine Fehler mehr da sind, dann stelle DisabledWegenPruefehlern auf false
    //der User kann jetzt abschicken, wenn er akzeptiert hat
    if(this.FormGroupNamen.length==0){
      //man zustimmen
      this.DisabledWegenPruefehlern=false
      this.disabledAGBacceptance=false
      this.abgabeKnopfGesperrt=false
    }else{
      this.DisabledWegenPruefehlern=true
      this.disabledAGBacceptance=true
      this.abgabeKnopfGesperrt=true
    }
  }
  abgabe(){
    if(this.valueAGBacceptance==true&&this.DisabledWegenPruefehlern==false){
      //Idee: Frustfrei das berechnet updaten
      this.DataService.UmweltchecksBhobj.subscribe(
        //Idee: ist die Abgabe erfolgreich wird das UmweltchecksBhobj genexted
        (Umweltchecks) => {
          console.log("Abgabe erfolgreich berechnet gesetzt:",Umweltchecks)
          this.berechnet = Umweltchecks.Umweltchecks[this.DataService.NummerUmweltcheck].berechnet
          this.alertService.success("Der Quick Check Umwelt wurde erfolgreich versendet. Vielen Dank!",false)           
      },
        (error) => {
          console.log("ERROR in Abgabe Quick Check Umwelt.", error)
          this.alertService.error("Fehler bei der Abgabe des Quick Check Umwelt. Bitte versuchen Sie es später nochmal oder kontaktieren Viabono.",false)
      }
      )
      this.alertService.success("...versende Ergebnisse...warten...",false)   
      this.DataService.abgabeQuickcheckUmwelt()
    }
  }
  accept(){
    //ich kann an dem Knopf nur rumspielen, wenn das noch nicht abgegeben wurde
    if (!this.berechnet) {
      this.valueAGBacceptance=!(this.valueAGBacceptance)
      //wenn es keine Fehler gibt, dann stelle an:
      if(this.FormGroupNamen.length==0&&this.valueAGBacceptance==true){
        this.abgabeKnopfGesperrt=false
      }
      //keine Fehler und AGB nicht akzeptiert -> gesperrt
      if(this.FormGroupNamen.length==0&&this.valueAGBacceptance==false){
        this.abgabeKnopfGesperrt=true
      }
    }
  }
}
