import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from "@angular/common/http";
import { Kunde, Heizmittelnamen, Heizmittelvalue, BHKWvalues } from "../_models/index";
import { OnInit } from "@angular/core";
import { Adresse, Adressen } from "../_models/index";
import { Anlage } from "../_models/anlage";
import { Restabfalltonne } from "../_models/restabfalltonne";
import { Umweltchecks } from "../_models/umweltchecks";
import { Einrichtung } from "../_models/einrichtung";
import { begleitfrage } from "../_models/begleitfrage";
import { Wellnessbereich } from "../_models/wellnessbereich";
import { FeWo } from "../_models/FeWo";
import { Observable } from "rxjs"
import { BehaviorSubject} from "rxjs"
import { AuthGuard } from "../_guards/index";

@Injectable({
    providedIn: "root",
  })

export class DataService {
   //Die relevanten Daten sind Oberservables, damit man dort subscriben kann, wenn Sie Sich ändern
   public KundenBhobj : BehaviorSubject<Kunde>;
   public AdressenBhobj : BehaviorSubject<Adressen>;
   public UmweltchecksBhobj : BehaviorSubject<Umweltchecks>;
   
   public KundenOberservable : Observable<Kunde>;
   public AdressenOberservable : Observable<Adressen>;

   public Kunde : Kunde;
   public Adressen : Adressen;
   public Umweltchecks : Umweltchecks;


   private  headers : HttpHeaders;

   public BHKWvalues : BHKWvalues;
   public NummerUmweltcheck :number 

   public rootDomain : string
   public headersOn : Boolean
   public Payload : any

   

    constructor(private http: HttpClient, private authGuardService : AuthGuard) {
        this.rootDomain = "https://www.quickcheckumwelt.de"
        this.headersOn=false
        this.Payload = {} as object
        

        this.NummerUmweltcheck = 0
        this.Kunde = new Kunde()
        this.Adressen = new Adressen()
        this.Umweltchecks= new Umweltchecks()

        //initialisieren der Behaviorobjects => getValue gibt das Objekt
        this.KundenBhobj = new BehaviorSubject<Kunde>(this.Kunde)
        this.AdressenBhobj   = new BehaviorSubject<Adressen>(this.Adressen)
        this.UmweltchecksBhobj  =new BehaviorSubject<Umweltchecks>(this.Umweltchecks)

        //initialisieren der Observables
        this.KundenOberservable = this.KundenBhobj.asObservable()  
        this.AdressenOberservable = this.AdressenBhobj.asObservable()  
        
        //initialiseren der Header
        this.headers = new HttpHeaders({"Access-Control-Allow-Origin":"*"}); 
        this.headers.append("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT, PATCH, DELETE");
        this.headers.append("Access-Control-Allow-Headers", "x-requested-with,content-type, origin, access-control-allow-origin, access-control-allow-headers");
        this.headers.append("Access-Control-Allow-Credentials", "true");
        this.headers.append("content-type","text/plain; charset=utf-8")   

        if(this.headersOn){
            this.Payload = {headers : this.headers}
        }else{
            this.Payload = {}
        }

        this.BHKWvalues=new BHKWvalues()
    }
    //Kunde:   
    getKunde() : Observable<Kunde> {
        return this.http.get<Kunde>(            
            "https://www.quickcheckumwelt.de/go/getKunde/",
            //{headers: this.headers}
        ) 
    }
    updateKunde(Kunde : Kunde){
        this.Kunde=Kunde        
        this.KundenBhobj.next(Kunde)
        console.log("updateKunde: KundenBhobj.getValue():", this.KundenBhobj.getValue())
    }
    saveKunde(){
        let Kunde = this.KundenBhobj.getValue()
        console.log("saveKunde: KundenBhobj.getValue():", Kunde)
        
        this.http.post("https://www.quickcheckumwelt.de/go/saveKunde/", Kunde)
        .subscribe(
            (response)=>console.log(response),
            (error)=>{
                console.log("DSsaveKunde",error)
                if(error.status==405||403||302){
                    this.authGuardService.deleteCurrentUser()
                }
            }

        )
    } 
    //Adressen
    getAdressen() : Observable<Adressen> {        
        return this.http.get<Adressen>(
        "https://www.quickcheckumwelt.de/go/getAdressen/",
        //{headers: this.headers}
        )    
    }
    //lokales Update
    updateFirmenAdresse(Firmenadresse: Adresse){
        this.Adressen=this.AdressenBhobj.getValue()
        this.Adressen.Firmenadresse=Firmenadresse       
        this.AdressenBhobj.next(this.Adressen)
        console.log("updateAdressen: KundenBhobj.getValue():", this.AdressenBhobj.getValue())
    }
    updateRechnungsAdresse(Rechnungsadresse: Adresse){
        this.Adressen=this.AdressenBhobj.getValue()
        this.Adressen.Rechnungsadresse=Rechnungsadresse       
        this.AdressenBhobj.next(this.Adressen)
        console.log("updateAdressen: KundenBhobj.getValue():", this.AdressenBhobj.getValue())
    }
    //Serverupdate
    saveFirmenAdresse() {
        let Adressen = this.AdressenBhobj.getValue()
        //Firmenadresse wegschicken:
        return this.http.post<Adresse>(
            "https://www.quickcheckumwelt.de/go/saveAdressen/", Adressen.Firmenadresse,
            //{headers: this.headers}
        )
          .subscribe(             
            (response : Adresse)=>{
                console.log(response)                    
            },
            (error)=>{ console.log(error)
                console.log("DSsaveFirmenAdresse",error)
                if(error.status==405||403||302){
                    this.authGuardService.deleteCurrentUser()
                }
            }
          );        
    }
    saveRechnungsAdresse() {
        let Adressen = this.AdressenBhobj.getValue()
        //Rechnungsadresse wegschicken:
        //Frage: Speichert er die Rechnungsadresse -> testen
        this.http.post<Adresse>(
          "https://www.quickcheckumwelt.de/go/saveAdressen/", Adressen.Rechnungsadresse,
          //{headers: this.headers}
        )
        .subscribe(             
          (response : Adresse)=>{
              console.log(response)                    
          },
          (error)=>{ 
            console.log("DSsaveKunde",error)
            if(error.status==405||403||302){
                this.authGuardService.deleteCurrentUser()
            }
          }
        );        
    }
    deleteRechnungsAdresse(){
        if(this.Umweltchecks.Umweltchecks.length>0){
            this.http.delete<number>(
                "https://www.quickcheckumwelt.de/go/deleteRechnungsadresse/" + this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].umweltcheck_id+"/",
                //{headers: this.headers}
                )
            .subscribe(             
                (response : any)=>{
                    console.log("DSdeleteRechnungsadresse:",response)                    
                },
                (error)=>{ 
                    console.log("DSdeleteRechnungsadresseError",error)
                    if(error.status==405||403||302){
                        this.authGuardService.deleteCurrentUser()
                    }
                }
            );
        }
    }
    getUmweltChecks()  : Observable<Umweltchecks>{        
        return this.http.get<Umweltchecks>(
            "https://www.quickcheckumwelt.de/go/getUmweltchecks/",
            //{headers: this.headers}
        )
    }
    updateUmweltchecks(Umweltchecks : Umweltchecks){
        this.Umweltchecks=Umweltchecks              
        this.UmweltchecksBhobj.next(Umweltchecks)        
    }
    saveUmweltChecks(){
        this.http.post<Umweltchecks>(
            "https://www.quickcheckumwelt.de/go/saveAdressen/", Umweltchecks,
            //{headers: this.headers}
        )
          .subscribe(             
            (response : Umweltchecks)=>{
                this.Umweltchecks=response
                console.log(this.Umweltchecks)                    
            },
            (error)=>{
                console.log("DSsaveKunde",error)
                if(error.status==405||403||302){
                    this.authGuardService.deleteCurrentUser()
                }
            }
          );
    }
    saveHotel(){
        this.http.post<any>(
            "https://www.quickcheckumwelt.de/go/saveHotel/"+this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].umweltcheck_id, this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Hotel,
            //{headers: this.headers}
        )
          .subscribe(             
            (response : any)=>{               
                console.log(response)                    
            },
            (error)=>{
               console.log("DSsaveHotel",error)
               if(error.status==405||403||302){
                   this.authGuardService.deleteCurrentUser()
               }
            }
          );
    }
    getAnlagenAusAnlangenArray(typ:string): Anlage[] {
        var anlagen : Anlage[] = []       
        if(this.Umweltchecks.Umweltchecks.length>0){
            if(this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen.length>0){
                for (let anlage of this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen) {
                    if(anlage.typ.includes(typ)){
                    //FALL: HEIZUNGEN:
                        //gehe das valuearray durch und füge den Namen des NameArrays als Namensposition:
                        var HeizungsNamen=new Heizmittelnamen()
                        var Heizungsvalues=new Heizmittelvalue()
                        var i = 0
                        for (let value of Heizungsvalues.Heizmittelvalues) { 
                            //FALL HEIZUNG
                            if(anlage.typ.includes(value))
                            {
                                //console.log("HEIZ Anlagentyp:",anlage.typ,"Heizmittelvalues:",Heizungsvalues.Heizmittelvalues[i],"Heizmittelname:",HeizungsNamen.Heizmittelnamen[i])
                                anlage.name=value
                            }
                            //FALL BHWK
                            if(anlage.typ.includes(this.BHKWvalues.BHKWvalues[i]))
                            {
                                //console.log("HEIZ Anlagentyp:",anlage.typ,"Heizmittelvalues:",Heizungsvalues.Heizmittelvalues[i],"Heizmittelname:",HeizungsNamen.Heizmittelnamen[i])
                                anlage.name=this.BHKWvalues.BHKWvalues[i]
                            }
                            //alle: Einheiten
                            if(anlage.typ.includes("kWh"))
                            {
                                //console.log("HEIZ Anlagentyp:",anlage.typ,"Heizmittelvalues:",Heizungsvalues.Heizmittelvalues[i],"Heizmittelname:",HeizungsNamen.Heizmittelnamen[i])
                                anlage.einheit="kWh"
                            }
                            if(anlage.typ.includes("kg"))
                            {
                                //console.log("HEIZ Anlagentyp:",anlage.typ,"Heizmittelvalues:",Heizungsvalues.Heizmittelvalues[i],"Heizmittelname:",HeizungsNamen.Heizmittelnamen[i])
                                anlage.einheit="kg"
                            }
                            if(anlage.typ.includes("Liter"))
                            {
                                //console.log("HEIZ Anlagentyp:",anlage.typ,"Heizmittelvalues:",Heizungsvalues.Heizmittelvalues[i],"Heizmittelname:",HeizungsNamen.Heizmittelnamen[i])
                                anlage.einheit="Liter"
                            }
                            i++
                        }
                        anlagen.push(anlage)
                    }
                }
            }   
        } 
        return anlagen
    }
    //Anlagen:
    //Teständerung
    addAnlage(anlage:Anlage){
        this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen.push(anlage)
        this.UmweltchecksBhobj.next(this.Umweltchecks)
    }
    addAnlagen(anlagen : Anlage[]){
        this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen=anlagen.concat(anlagen, this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen)
        this.UmweltchecksBhobj.next(this.Umweltchecks)
    } 
    deleteAnlagen(){
        if(this.Umweltchecks.Umweltchecks.length>0){
            var length=this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen.length
            this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen.forEach( (item,index) => {
            delete this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen[index]
            });            
            //Einträge löschen
            for (let a of this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen){
                if(length>0){
                   this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen.shift()
                   length=length-1
                }
            }
        }
        this.UmweltchecksBhobj.next(this.Umweltchecks) 
    }
    deleteAnlage(typ : string){
        if(this.Umweltchecks.Umweltchecks.length>0){
            var length=this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen.length
            this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen.forEach( (item,index) => {
                if(this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen[index].typ.includes(typ)){
                    delete this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen[index]
                    //Problem: jetzt habe ich Ghosts in der Datenbank, weil der Eintrag nur genullt ist.
                    //Ich müsste den Eintrag noch löschen...
                }   
            });            
        }
        this.UmweltchecksBhobj.next(this.Umweltchecks) 
    }           
    //Restabfalltonn
    addRestabfalltonne(tonne : Restabfalltonne){
        this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Restabfalltonnen.push(tonne)
        this.UmweltchecksBhobj.next(this.Umweltchecks)
    }
    addRestabfalltonnen(tonnen :Restabfalltonne[]){
        this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Restabfalltonnen=tonnen.concat(tonnen, this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Restabfalltonnen)
        this.UmweltchecksBhobj.next(this.Umweltchecks)
    }
    deleteRestabfalltonnenInDS(){
        if(this.Umweltchecks.Umweltchecks.length>0){
            var length=this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Restabfalltonnen.length
            this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Restabfalltonnen.forEach( (item,index) => {
            delete this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Restabfalltonnen[index]
            });  
            //die ersten Elemente rausslöschen, gingt nich auch in der foreach
           
            for(let r of this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Restabfalltonnen){
             if(length>=-1){
                this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Restabfalltonnen.shift()
                length=length-1
              } 
            }
            this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Restabfalltonnen.shift()            
        }
        this.UmweltchecksBhobj.next(this.Umweltchecks)
        console.log("Dataservice: nach Delte Restabfalltonnen", this.Umweltchecks) 
    }
    //Getbegleitfrage
    getbegleitfrageAusbegleitfrageArray(typ:string): begleitfrage[] {
        var begleitfragen : begleitfrage[] = []
        //hier sollte immer nur eine Antwort drin sein:       
            for (let begleitfrage of this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Begleitfrage) {
                if(begleitfrage.begleitfrage_typ===typ){
                    begleitfragen.push(begleitfrage)
                }
            }        
        return begleitfragen
    }
    upsertbegleitfrage(begleitfrage:begleitfrage){
        if(this.Umweltchecks.Umweltchecks != undefined && this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung != undefined){
            begleitfrage.einrichtungs_id=this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.einrichtungs_id
            var updated = false
        }
        if (this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Begleitfrage!=undefined){
            this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Begleitfrage.forEach( (item,i) => {
                if (item.begleitfrage_typ==begleitfrage.begleitfrage_typ){
                    updated=true
                    //update diese Frage
                    this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Begleitfrage[i]=begleitfrage
                }
            })
        }
        if(updated==false){
            var begleitfragen : begleitfrage[]=[];
            begleitfragen.push(begleitfrage)

            //Problem: Wenn undefined, dann häng es ran, sonst pusche
            if (this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Begleitfrage!=undefined){
                this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Begleitfrage.push(begleitfrage)    
            }else{
                this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Begleitfrage=begleitfragen
            }
        }
        console.log("UpsertBegleitfrage:",begleitfrage)
        this.UmweltchecksBhobj.next(this.Umweltchecks)
        //Pusche den Server
        this.http.post<begleitfrage[]>(
            "https://www.quickcheckumwelt.de/go/saveBegleitfragen/" + this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].umweltcheck_id+"/"+ begleitfrage.begleitfrage_typ_id +"/",  this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Begleitfrage,
            //{headers: this.headers}
        )
          .subscribe(             
            (response : any)=>{
                console.log("DSsaveBegleitfragenResponse:",response)                    
            },
            (error)=>{ 
                console.log("DSsaveBegleitfragenError",error)
                if(error.status==405||403||302){
                    this.authGuardService.deleteCurrentUser()
                }
            }
          )
    }
    deletebegleitfragen(antworttyp_id : number){
        if(this.Umweltchecks.Umweltchecks.length>0){
            //die Route ist an dieser Stelle absichtlich verbaut, da das Backend rumspackt und einen FÜllwert braucht, hier 0
            this.http.delete<number>(
                "https://www.quickcheckumwelt.de/go/deleteBegleitfragen/" + this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].umweltcheck_id + "/" + antworttyp_id + "/"+ 0 + "/",
                //{headers: this.headers}                 
                )
            .subscribe(             
                (response : any)=>{
                    console.log("DSdeletBegleitfragenResponse:",response)                    
                },
                (error)=>{
                    console.log("DSdeleteBegleitfragenError",error)
                    if(error.status==405||403||302){
                        this.authGuardService.deleteCurrentUser()
                    }
                }
            );
        }

    }
    saveRestabfalltonnen(){
        this.http.post<Restabfalltonne[]>(
            "https://www.quickcheckumwelt.de/go/saveRestabfalltonnen/" + this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].umweltcheck_id+"/", this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Restabfalltonnen,
            //{headers: this.headers}
        )
          .subscribe(             
            (response : any)=>{
                console.log("DSsaveRestabfalltonnenResponse:",response)                    
            },
            (error)=>{ 
                console.log("DSsaveRestabfalltonnenError",error)
                if(error.status==405||403||302){
                    this.authGuardService.deleteCurrentUser()
                }
            }
          );
    }
    deleteRestabfalltonnen(){
        if(this.Umweltchecks.Umweltchecks.length>0){
            this.http.delete<number>(
                "https://www.quickcheckumwelt.de/go/deleteRestabfalltonnen/" + this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].umweltcheck_id+"/",
                //{headers: this.headers}                 
                )
            .subscribe(             
                (response : any)=>{
                    console.log("DSdeleteRestabfalltonnenResponse:",response)                    
                },
                (error)=>{
                    console.log("DSdeleteRestabfalltonnenError",error)
                    if(error.status==405||403||302){
                        this.authGuardService.deleteCurrentUser()
                    }
                }
            );
        }
    }
    saveAnlagen(){
        this.http.post<Anlage[]>(
            "https://www.quickcheckumwelt.de/go/saveAnlagen/" + this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].umweltcheck_id+"/", this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Anlagen,
            //{headers: this.headers}                 
            )
          .subscribe(             
            (response : any)=>{
                console.log("DSsaveAnlagenResponse:",response)                    
            },
            (error)=>{
                 console.log("DSsaveAnlagenError",error)
                 if(error.status==405||403||302){
                     this.authGuardService.deleteCurrentUser()
                 }
            }

          );
    }
    saveEinrichtung(){
        this.http.post<Einrichtung>(
            "https://www.quickcheckumwelt.de/go/saveEinrichtung/" + this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].umweltcheck_id+"/", this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung,
            //{headers: this.headers}                
             )
          .subscribe(             
            (response : any)=>{
                console.log("DSsaveEinrichtungResponse:",response)                    
            },
            (error)=>{
                console.log("DSEinrichtungError",error)
                if(error.status==405||403||302){
                    this.authGuardService.deleteCurrentUser()
                }
            }
          );
    }
    saveFeWo(){
        this.http.post<FeWo>(
            "https://www.quickcheckumwelt.de/go/saveFeWo/" + this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].umweltcheck_id+"/", this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.FeWo,
            //{headers: this.headers}                 
            )
          .subscribe(             
            (response : any)=>{
                console.log("DSsaveFeWoResponse:",response)                    
            },
            (error)=>{
                console.log("DSsaveFeWoError",error)
                if(error.status==405||403||302){
                    this.authGuardService.deleteCurrentUser()
                }
            }
          );
    } 
    deleteFeWo(FeWoID : number){
      if(this.Umweltchecks.Umweltchecks.length>0){
        this.http.delete<number>(
            "https://www.quickcheckumwelt.de/go/deleteFeWo/" + this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].umweltcheck_id+"/",
            //{headers: this.headers}                
             )
          .subscribe(             
            (response : any)=>{
                console.log("DSdeleteFeWoResponse:",response)                    
            },
            (error)=>{
                console.log("DSdeleteFeWoError",error)
                if(error.status==405||403||302){
                    this.authGuardService.deleteCurrentUser()
                }
            }
          );
        }
    }
    saveWellnessbereich(){
        this.http.post<Wellnessbereich>(
            "https://www.quickcheckumwelt.de/go/saveWellnessbereich/" + this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].umweltcheck_id+"/", this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].Einrichtung.Wellnessbereich,
            //{headers: this.headers}                 
            )
          .subscribe(             
            (response : any)=>{
                console.log("DSsaveWellnessbereichResponse:",response)                    
            },
            (error)=>{ 
                console.log("DSsaveWellnessbereichError",error)
                if(error.status==405||403||302){
                    this.authGuardService.deleteCurrentUser()
                }
            }
          );
    }
    deleteWellnessbereich(){
      if(this.Umweltchecks.Umweltchecks.length>0){
        this.http.delete<number>(
            "https://www.quickcheckumwelt.de/go/deleteWellnessbereich/" + this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].umweltcheck_id+"/",
            //{headers: this.headers}                
             )
          .subscribe(             
            (response : any)=>{
                console.log("DSdeleteWellnessbereichResponse:",response)                    
            },
            (error)=>{
                console.log("DSdeleteWellnessbereichError",error)
                if(error.status==405||403||302){
                    this.authGuardService.deleteCurrentUser()
                }
            }
          );
      }
    }
    deleteHotel(){
      if(this.Umweltchecks.Umweltchecks.length>0){
        this.http.delete<number>(
            "https://www.quickcheckumwelt.de/go/deleteHotel/" + this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].umweltcheck_id+"/",
            //{headers: this.headers}                
             )
          .subscribe(             
            (response : any)=>{
                console.log("DSdeleteHotelResponse:",response)                    
            },
            (error)=>{
                console.log("DSdeleteHotelError",error)
                if(error.status==405||403||302){
                    this.authGuardService.deleteCurrentUser()
                }
            }
          );
      }
    }
    abgabeQuickcheckUmwelt(){
        this.http.post<any>(
            "https://www.quickcheckumwelt.de/go/abgabeQuickcheckUmwelt/" + this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].umweltcheck_id+"/", "{\"response\":\"abgabeQuickcheckUmwelt\" ",
            //{headers: this.headers}                
             )
          .subscribe(             
            (response : any)=>{
                this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck].berechnet=true
                console.log("DSabgabeQuickcheckUmwelt",this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck])
                console.log("DSabgabeQuickcheckUmweltResponse:",response)
                this.UmweltchecksBhobj.next(this.Umweltchecks)                             
            },
            (error)=>{ 
                console.log("DSabgabeQuickcheckUmwelt",this.Umweltchecks.Umweltchecks[this.NummerUmweltcheck])
                console.log("DSabgabeQuickcheckUmweltError",error)
                if(error.status==405||403||302){
                    this.authGuardService.deleteCurrentUser()
                }            
            }
          );
    }
}
