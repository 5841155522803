export class FeWo {
    ferienwohnungseinrichtungs_id : number
    anzahl_der_ferienwohnungen : number
    anzahl_der_uebernachtungen_mai_bis_oktober : number
    anzahl_der_uebernachtungen_november_bis_april : number
    anzahl_der_gaesteanreisen_pro_jahr : number
    durchschnittliche_groesse_der_fe_wo_in_m2 : number
    durchschnittliche_maximale_belegungszahl_der_fe_wo : number
    
    constructor(){
        this.ferienwohnungseinrichtungs_id=0
        this.anzahl_der_ferienwohnungen =0
        this.anzahl_der_uebernachtungen_mai_bis_oktober =0
        this.anzahl_der_uebernachtungen_november_bis_april =0
        this.anzahl_der_gaesteanreisen_pro_jahr =0
        this.durchschnittliche_groesse_der_fe_wo_in_m2 =0
        this.durchschnittliche_maximale_belegungszahl_der_fe_wo =0
    }
}