import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/index';
import { LoginComponent } from './login/index';
import { RegisterComponent } from './register/index';
import { AuthGuard } from './_guards/index';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { EingabeComponent } from './eingabe/eingabe.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { RegisterpageComponent } from './registerpage/registerpage.component';

const appRoutes: Routes = [
    { path: '', component: LandingpageComponent},
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LoginComponent },    
    { path: 'register', component: RegisterpageComponent }, 
    { path: 'eingabe', component: EingabeComponent, canActivate: [AuthGuard]}, // 
    { path: 'impressum', component: ImpressumComponent }, 
    { path: 'datenschutz', component: DatenschutzComponent },    
    { path: 'redirectToLogin', component: LoginComponent },

    

    // otherwise redirect to home
    { path: '**', redirectTo: '/login' }
];
export const routing = RouterModule.forRoot(appRoutes);