export class BHKWvalues {
    BHKWvalues : string[]=[];
        constructor(){          
            this.BHKWvalues.push("Mit Erdgas (in kWh angegeben) betriebenes BHKW",          //0
                                "Mit Erdgas (in m³ angegeben) betriebenes BHKW",            //1
                                "Mit Heizoel (in Litern angegeben) betriebenes BHKW",       //2
                                "Mit Fluessiggas (in Litern angegeben) betriebenes BHKW",   //3
                                "Mit Fluessiggas (in kg angegeben) betriebenes BHKW",       //4
                                //"Mit Fernwaerme (in kWh angegeben) betriebenes BHKW",       //5
                                "Mit Hackschnitzel/Scheitholz (in t angegeben) betriebenes BHKW", //6
                                "Mit Pellets (in t angegeben) betriebenes BHKW",            //7
                                //"Mit Solarthermie (in kWh angegeben) betriebenes BHKW",     //8
                                //"Mit Geothermie (in kWh angegeben) betriebenes BHKW",       //9
                                //"Mit Nahwaerme (in kWh angegeben) betriebenes BHKW",        //10
                                "Mit Pflanzenoel (in Litern angegeben) betriebenes BHKW",   //11
                                "Mit Biogas (in m³ angegeben) betriebenes BHKW",            //12
                                "Mit Biogas (in kWh angegeben) betriebenes BHKW",           //13
            )
        }       
    }