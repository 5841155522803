import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/index';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  login: Boolean;

  constructor(private authenticationService: AuthenticationService) { 
    this.login=this.authenticationService.loggedinBhSubj.getValue()
    console.log("Set Login auf login Autservice:", this.login)
    this.authenticationService.loggedinBhSubj.subscribe(
      (data) => this.login=this.authenticationService.loggedinBhSubj.getValue()
    );
  }
  ngOnInit() {
    
  }
  logout(){        
    this.authenticationService.logout();
  }
}
