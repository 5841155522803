import { Injectable } from '@angular/core';
import { FormGroup, FormControl,FormArray,  ValidationErrors } from '@angular/forms';
import { GroupPruefArray } from '../_models/grouppruefarray';
import { BehaviorSubject} from 'rxjs/Rx';
import { Observable } from 'rxjs/Rx'

@Injectable({
  providedIn: 'root'
})


export class PruefService {
  public InvalidFormGroups : GroupPruefArray[];
  public InvalidFormGroupBhSubj : BehaviorSubject<GroupPruefArray[]>;
  public InvalidFormGroupOberservable : Observable<GroupPruefArray[]>;

  public NameArray : string[]=[]
  public ErrorArray: ValidationErrors[]=[]  

  constructor() {
    this.InvalidFormGroups = new Array()
    this.InvalidFormGroupBhSubj = new BehaviorSubject<GroupPruefArray[]>(this.InvalidFormGroups)
    this.InvalidFormGroupOberservable=this.InvalidFormGroupBhSubj.asObservable()
  }
  ResetPruefungService(){
    this.InvalidFormGroups=[]
    this.InvalidFormGroups.length=0
    this.InvalidFormGroupBhSubj.next(this.InvalidFormGroups)
  }
  ResetNamensAndErrors(){
    //this.ErrorArray.length=0
    this.ErrorArray=[]

    //this.NameArray.length=0
    this.NameArray=[]
  }
  pushNameErrorArray(name : string, error : ValidationErrors){       
      this.ErrorArray.push(error)
      this.NameArray.push(name)
  }
  pushInvalidFormgroup(name:string, FormGroup : FormGroup){
    var Group = new GroupPruefArray();

    Group.name = name
    Group.FormGroup = FormGroup
    Group.FormControlNames = this.NameArray
    Group.FormControlErrors = this.ErrorArray
    //pruefung, ob das Array leer => Keine Fehler 
    if(Group.FormControlNames.length>0){
       this.InvalidFormGroups.push(Group)
       this.InvalidFormGroupBhSubj.next(this.InvalidFormGroups)
    }
    console.log("pruefservice: InvalidFormGroups",this.InvalidFormGroups)
    this.ResetNamensAndErrors()
  }  
}
