export class Heizmittelvalue {
    Heizmittelvalues: string[]=[]
        constructor(){
            this.Heizmittelvalues.push("Heizung Erdgas in kWh",             //0
                                        "Heizung Erdgas in m³",             //1
                                        "Heizung Heizoel in Litern",        //2
                                        "Heizung Fluessiggas in Litern",    //3
                                        "Heizung Fluessiggas in kg",        //4
                                        "Heizung Fernwaerme in kWh",        //5
                                        "Heizung Hackschnitzel/Scheitholz in t",//6
                                        "Heizung Pellets in t",            //7
                                        "Heizung Solarthermie in kWh",      //8
                                        "Heizung Geothermie in kWh",        //9
                                        "Heizung Nahwaerme in kWh",         //10
                                        "Heizung Pflanzenoel in Litern",    //11
                                        "Heizung Biogas in m³",             //12
                                        "Heizung Biogas in kWh",            //13
                                        "Waermepumpe")            //14
        }    
}