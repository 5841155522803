import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule}    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
import { fakeBackendProvider } from './_helpers/index';
import { AppComponent }  from './app.component';
import { routing }        from './app.routing';
import { AlertComponent } from './_directives/index';
import { AuthGuard } from './_guards/index';
import { JwtInterceptor } from './_helpers/index';
import { AlertService, AuthenticationService, UserService } from './_services/index';
import { HomeComponent } from './home/index';
import { LoginComponent } from './login/index';
import { RegisterComponent } from './register/index';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { NavigationComponent } from './navigation/navigation.component';
import { EingabeComponent } from './eingabe/eingabe.component';
import { FooterComponent } from './footer/footer.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NameEditorComponent } from './name-editor/name-editor.component';
import { KundeneingabeComponent } from './kundeneingabe/kundeneingabe.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule} from '@angular/material/button';
import { MatRadioModule} from '@angular/material/radio';
import { MatCheckboxModule} from '@angular/material/checkbox';

import { MatInputModule } from '@angular/material/input';
import { TestComponent } from './test/test.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { ErrorStateMatcher } from '@angular/material/core';
import { BetriebsdatenComponent } from './betriebsdaten/betriebsdaten.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { StromHeizungComponent } from './strom-heizung/strom-heizung.component';
import { RestabfallComponent } from './restabfall/restabfall.component';
import { MatStepperModule } from '@angular/material/stepper';
import { DataService } from './_services/data.service';
import { CookieService } from 'ngx-cookie-service';
import { AbgabeComponent } from './abgabe/abgabe.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PruefungComponent } from './pruefung/pruefung.component';
import { IntroComponent } from './intro/intro.component';
import { WasserComponent } from './wasser/wasser.component';
import { StromComponent } from './strom/strom.component';
import { ReplacePipe } from './replace.pipe';;
import { RegisterpageComponent } from './registerpage/registerpage.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        routing,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatButtonModule, 
        MatCheckboxModule,
        MatInputModule,       
        MatIconModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatStepperModule,
        MatRadioModule,  
        NgbModule, 
        FontAwesomeModule

        //ErrorStateMatcher,

    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        LandingpageComponent,
        NavigationComponent,
        EingabeComponent,
        FooterComponent,
        ImpressumComponent ,
        DatenschutzComponent ,
        NameEditorComponent,
        KundeneingabeComponent,
        TestComponent,
        BetriebsdatenComponent,
        StromHeizungComponent,
        RestabfallComponent,
        AbgabeComponent,
        PruefungComponent,
        IntroComponent,
        WasserComponent,
        StromComponent,
        ReplacePipe,
        RegisterpageComponent
    ],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
        ErrorStateMatcher,
        UserService,    
        DataService,    
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },

        // provider used to create fake backend
        fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }