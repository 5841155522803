import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import {DataService} from './_services/data.service'

import '../assets/app.css';

@Component({
    moduleId: module.id.toString(),
    selector: 'app',
    templateUrl: 'app.component.html'
})

export class AppComponent { 
    constructor(private Dataservice : DataService) { 
        
        this.Dataservice.getKunde()    
    }

ngOnInit(){

}

}
