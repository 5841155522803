import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { FormGroup,FormControl, Validators } from '@angular/forms';
import { DataService } from '../_services/data.service';
import { PruefService } from '../_services/pruefservice.service';
import { begleitfrage } from "../_models/begleitfrage";

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {
  @Input() saveIntroDaten : EventEmitter<void>;
  @Input() pruefeEingabe : EventEmitter<void>;

  quelle:FormControl;
  begleitfrage : begleitfrage;

  pullcounter:number;
  
  constructor(private Dataservice : DataService, private Pruefservice : PruefService) {
    this.quelle = new FormControl('',[Validators.required])
    this.begleitfrage = new begleitfrage()
    this.begleitfrage.begleitfrage_typ="quelle"
    this.begleitfrage.begleitfrage_typ_id=1
    this.begleitfrage.antwort_typ_id=0
    this.pullcounter=0
  }
  ngOnInit() { 
    this.Dataservice.UmweltchecksBhobj.subscribe(
      //Problem: wird einmal aufgerufen beim Konstruktor und einmal, wenn die Datengeupdated werden.
      (Umweltchecks) => { 
        this.pullcounter++
      if(Umweltchecks.Umweltchecks&&this.pullcounter>1){
          var begleitfragen = this.Dataservice.getbegleitfrageAusbegleitfrageArray("quelle")
          
          //Falls es mehr als eine gibt, nimm nur die erste
          if(begleitfragen.length>0){
            this.begleitfrage = begleitfragen[0]
          }
          console.log("Begleitfrage: ", this.begleitfrage)
          this.quelle.setValue(String(this.begleitfrage.antwort_typ_id))
      }
      },
      (error) => {console.log("ERROR in Introcomponente.", error)},
    )
    this.saveIntroDaten.subscribe(
      ()=>{
        this.begleitfrage.antwort_id=Number(this.quelle.value)
        this.begleitfrage.antwort_typ_id=Number(this.quelle.value)
        this.pruefeIntro()
        //absenden ans Backend!
        this.Dataservice.upsertbegleitfrage(this.begleitfrage)
      },
        err=>{console.log(err)        
        })
        this.pruefeEingabe.subscribe(()=>{
          console.log("pruefe Start")
          this.pruefeIntro()
        })        
  }
  pruefeIntro(){
    //Array zusammenkloppen: "Name", "Error"
    if(this.quelle.value==""||this.quelle.invalid||Number(this.quelle.value)==0){
      this.Pruefservice.pushNameErrorArray("Start: Woher kennen Sie den Quick Check Umwelt? Bitte beantworten Sie die Frage.",this.quelle.errors)
      this.Pruefservice.pushInvalidFormgroup("Start:", null)
    }
  }
}
