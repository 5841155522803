export class Wellnessbereich {
    wellnessbereich_id: number;
    groesse_in_m2: number;
    anzahl_der_saunen: number;
    volumen_schwimmbad_in_m3: number;
    Oeffnungswochen_pro_jahr: number;
    
    constructor(){
        this.wellnessbereich_id=0
        this.groesse_in_m2=0
        this.anzahl_der_saunen=0
        this.volumen_schwimmbad_in_m3=0
        this.Oeffnungswochen_pro_jahr=0        
    }
}