export class Heizmittelnamen {
//Heizmittelnamen: {value: string; name: string}[]=[];
Heizmittelnamen: string[]=[]
    constructor(){
        /*
        this.Heizmittelnamen.push({value:"Erdgas",name: "Erdgas"})
        this.Heizmittelnamen.push({value:"Heizoel",name: "Heizöl"})
        this.Heizmittelnamen.push({value:"Fluessiggas",name: "Flüssiggas"})
        this.Heizmittelnamen.push({value:"Fernwaerme",name: "Fernwärme"})
        this.Heizmittelnamen.push({value:"Heizung",name: "Hackschnitzel/Scheitholz"})
        this.Heizmittelnamen.push({value:"Pellets",name: "Pellets"})
        this.Heizmittelnamen.push({value:"Solarthermie",name: "Solarthermie"})
        this.Heizmittelnamen.push({value:"Geothermie",name: "Geothermie"})
        this.Heizmittelnamen.push({value:"Nahwaerme",name: "Nahwärme"})
        */
        this.Heizmittelnamen.push("Erdgas in kWh",              //0
                                    "Erdgas in m³",             //1
                                    "Heizoel",                  //2
                                    "Fluessiggas in Litern",    //3
                                    "Fluessiggas in kg",        //4
                                    "Fernwaerme",               //5
                                    "Hackschnitzel/Scheitholz", //6
                                    "Pellets",                  //7
                                    "Solarthermie",             //8
                                    "Geothermie",               //9
                                    "Nahwärme",                 //10
                                    "Pflanzenoel in Litern",    //11
                                    "Biogas in m³",             //12
                                    "Biogas in kWh",            //13
                                    "Waermepumpe")               //14
    }
}