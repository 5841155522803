import { Component, OnInit, AfterViewInit, Input, EventEmitter,ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
import { MatSlideToggle} from '@angular/material/slide-toggle';
import { Validators} from '@angular/forms';
import { ErrorStateMatcher} from '@angular/material/core';
import { DataService } from '../_services/data.service';
import { PruefService } from '../_services/pruefservice.service';
import { Injectable } from '@angular/core';
import { Kunde, Adressen, Umweltchecks} from "../_models/index";
import { AuthGuard } from '../_guards/index';

/** custom Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-kundeneingabe',
  templateUrl: './kundeneingabe.component.html',
  styleUrls: ['./kundeneingabe.component.css'],
})
@Injectable({
  providedIn: 'root',
})
export class KundeneingabeComponent implements OnInit  {
  @Input() saveKunde : EventEmitter<void>;
  @Input() saveAdressen : EventEmitter<void>;
  @Input() pruefeEingabe : EventEmitter<void>;
  @ViewChild('Rechnungsadresse', { static: true }) _SlideToggle_Rechnungsaddresse: MatSlideToggle;
    kontaktdatenFormGroup : FormGroup;
    FirmenAddressFormGroup: FormGroup;
    RechnungsAddressFormGroup: FormGroup;

    kontaktdatenFormArray : FormArray
    kontaktdatenNamenArray : string[]
    FirmenAdressFormArray: FormArray
    FirmenAdressNamenArray : string[]
    RechnungsAdressFormArray : FormArray
    RechnungsAdressNamenArray : string[]
 
    matcher = new MyErrorStateMatcher()

    KundeInComponente : Kunde;
    AdressenInComponente: Adressen;
    pullcounter : number
  
  constructor(private Dataservice : DataService,private Pruefservice : PruefService, private authGuardService : AuthGuard) {      
    this.KundeInComponente = new Kunde()
    this.AdressenInComponente = new Adressen()
    this.pullcounter=0
    //KundenFormgroup Deklarieren
    this.kontaktdatenFormGroup = new FormGroup({  
      kunden_id: new FormControl(''),
      //Kundenobjekt:
      name_des_betriebes: new FormControl('',[Validators.required]), //0
      kunden_nummer: new FormControl('',[]),                         //1
      ansprechpartner: new FormControl('',[Validators.required]),    //2
      ansprechpartner_ansprache: new FormControl('',[Validators.required]),    //3
      ansprechpartner_email : new FormControl('', [Validators.required, Validators.email]), //4
      ansprechpartner_mobil : new FormControl('', []),               //5
      ansprechpartner_telefon : new FormControl('', [Validators.required]),    //6

      notiz: new FormControl('', []), 
    });
    this.kontaktdatenNamenArray=new Array()
    this.kontaktdatenNamenArray.push("Name des Betriebs")
    this.kontaktdatenNamenArray.push("KundenNummer")
    this.kontaktdatenNamenArray.push("Ansprechpartner")
    this.kontaktdatenNamenArray.push("Ansprechpartner Email")
    this.kontaktdatenNamenArray.push("Ansprechpartner Mobil")    
    this.kontaktdatenNamenArray.push("Ansprechpartner Telefon")    
    this.kontaktdatenNamenArray.push("Notiz")

    this.kontaktdatenFormArray=new FormArray([
      new FormControl(),
      new FormControl(),
      new FormControl(),
      new FormControl(),
      new FormControl()      
    ])
    //Firmenadresse deklarieren
    this.FirmenAddressFormGroup = new FormGroup({  
      name: new FormControl('',[Validators.required]),    //0
      strasse: new FormControl('',[Validators.required]), //1
      adresszusatz: new FormControl('',[]),               //2
      postleitzahl: new FormControl('',[Validators.required]), //3
      ort: new FormControl('',[Validators.required]),     //4
      land : new FormControl('', [Validators.required]),                     //5
      UStID: new FormControl('', [Validators.required]),  //6
    });
    this.FirmenAdressNamenArray = new Array()
    this.FirmenAdressNamenArray.push("Name")
    this.FirmenAdressNamenArray.push("Straße")
    this.FirmenAdressNamenArray.push("Adresszusatz")
    this.FirmenAdressNamenArray.push("Postleitzahl")
    this.FirmenAdressNamenArray.push("Ort")
    this.FirmenAdressNamenArray.push("Land")
    this.FirmenAdressNamenArray.push("UStID")

    this.FirmenAdressFormArray=new FormArray([
      new FormControl(), //0
      new FormControl(), //1
      new FormControl(), //2 
      new FormControl(), //3 
      new FormControl(), //4 
      new FormControl(), //5       
      new FormControl()  //6    
    ])
    //Rechnungsadress deklarieren
    this.RechnungsAddressFormGroup = new FormGroup({  
      checkedFormControl_Rechnungsadresse : new FormControl('',[Validators.required]), //0
      name: new FormControl('',[Validators.required]),                                 //1
      strasse: new FormControl('',[Validators.required]),                              //2
      adresszusatz: new FormControl('',[Validators.nullValidator]),                    //3
      postleitzahl: new FormControl('',[Validators.required,Validators.maxLength(6),Validators.minLength(4)]),                         //4
      ort: new FormControl('',[Validators.required]),                                  //5
      land : new FormControl('', [Validators.required]),                               //6
      UStID: new FormControl('', [Validators.required]),                               //7
    });
    this.RechnungsAdressNamenArray = new Array()
    this.RechnungsAdressNamenArray.push("Rechnungsaddresse ist gleich Firmenaddresse")
    this.RechnungsAdressNamenArray.push("Firmenname")
    this.RechnungsAdressNamenArray.push("Straße")
    this.RechnungsAdressNamenArray.push("Adresszusatz")
    this.RechnungsAdressNamenArray.push("Postleitzahl")
    this.RechnungsAdressNamenArray.push("Ort")
    this.RechnungsAdressNamenArray.push("land")
    this.RechnungsAdressNamenArray.push("UStID")
    this.RechnungsAdressFormArray=new FormArray([
      new FormControl(), //0
      new FormControl(), //1
      new FormControl(), //2 
      new FormControl(), //3 
      new FormControl(), //4 
      new FormControl(), //5       
      new FormControl(), //6
      new FormControl()  //7    
    ])
    this.matcher = new MyErrorStateMatcher();
    this.RechnungsAddressFormGroup.controls.checkedFormControl_Rechnungsadresse.setValue(false)
  }     
  ngOnInit(){   
    //subscribe auf den Kunden und 
    this.Dataservice.getKunde()
      //schreib den Scheiß ins Formular
      .subscribe(             
        (response : Kunde)=>{
          this.KundeInComponente = response

          //Kunden im Dataservice speichern
          this.Dataservice.updateKunde(this.KundeInComponente)

          console.log("getKunde FUNKTIONIERT!", this.KundeInComponente)
              //Kunden befüllen:
              this.kontaktdatenFormGroup.controls.name_des_betriebes.setValue(this.KundeInComponente.name_des_betriebes)
              this.kontaktdatenFormGroup.controls.ansprechpartner.setValue(this.KundeInComponente.ansprechpartner)
              this.kontaktdatenFormGroup.controls.ansprechpartner_email.setValue(this.KundeInComponente.ansprechpartner_email)
              this.kontaktdatenFormGroup.controls.ansprechpartner_ansprache.setValue(this.KundeInComponente.ansprechpartner_ansprache)

              //pruefung auf null,...leider....Fuck Typescript!
              if ( this.KundeInComponente.ansprechpartner_mobil != null ) {
                 this.kontaktdatenFormGroup.controls.ansprechpartner_mobil.setValue(this.KundeInComponente.ansprechpartner_mobil)
              }
              if ( this.KundeInComponente.ansprechpartner_telefon != null ) {
                this.kontaktdatenFormGroup.controls.ansprechpartner_telefon.setValue(this.KundeInComponente.ansprechpartner_telefon)
              }
              if ( this.KundeInComponente.notiz != null ) {
                this.kontaktdatenFormGroup.controls.notiz.setValue(this.KundeInComponente.notiz)
                console.log(this.KundeInComponente.notiz)
              }


        },
        (error)=> console.log(error)
      );
      //get Adressen
      this.Dataservice.getAdressen()
      .subscribe(             
        (response : Adressen)=>{
          //speichere State in DIESER Componente!
          this.AdressenInComponente = response
          //im Dataservice speichern
          this.Dataservice.updateFirmenAdresse(this.AdressenInComponente.Firmenadresse)
          this.Dataservice.updateRechnungsAdresse(this.AdressenInComponente.Rechnungsadresse)

          console.log("getAdresse FUNKTIONIERT!", this.AdressenInComponente)
          console.log("Rechnungsadresse:", this.AdressenInComponente.Rechnungsadresse)         
          
          //FirmenAdresse befüllen:
          if(this.AdressenInComponente.Firmenadresse!=null){
             this.FirmenAddressFormGroup.controls.strasse.setValue(this.AdressenInComponente.Firmenadresse.strasse)
             this.FirmenAddressFormGroup.controls.ort.setValue(this.AdressenInComponente.Firmenadresse.ort)
             this.FirmenAddressFormGroup.controls.postleitzahl.setValue(this.AdressenInComponente.Firmenadresse.postleitzahl) 
             this.FirmenAddressFormGroup.controls.land.setValue(this.AdressenInComponente.Firmenadresse.land) 
             this.FirmenAddressFormGroup.controls.UStID.setValue(this.AdressenInComponente.Firmenadresse.UStID) 

             //pruefung auf null,...leider....Fuck Typescript!
             if ( this.AdressenInComponente.Firmenadresse != null ) {
              this.FirmenAddressFormGroup.controls.name.setValue(this.AdressenInComponente.Firmenadresse.name)             
              this.FirmenAddressFormGroup.controls.adresszusatz.setValue(this.AdressenInComponente.Firmenadresse.adresszusatz)              
            } 
          }
          //RechnungsAdresse befüllen:
          if(this.AdressenInComponente.Rechnungsadresse!=null){
            this._SlideToggle_Rechnungsaddresse.toggle()
            setTimeout(() => {
              this.RechnungsAddressFormGroup.controls.strasse.setValue(this.AdressenInComponente.Rechnungsadresse.strasse)
              this.RechnungsAddressFormGroup.controls.ort.setValue(this.AdressenInComponente.Rechnungsadresse.ort)
              this.RechnungsAddressFormGroup.controls.postleitzahl.setValue(this.AdressenInComponente.Rechnungsadresse.postleitzahl) 
              this.RechnungsAddressFormGroup.controls.land.setValue(this.AdressenInComponente.Rechnungsadresse.land) 
              this.RechnungsAddressFormGroup.controls.UStID.setValue(this.AdressenInComponente.Rechnungsadresse.UStID) 
            
            },500)
            
            //pruefung auf null,...leider....Fuck Typescript!
            if ( this.AdressenInComponente.Firmenadresse.name != null ) {
             this.RechnungsAddressFormGroup.controls.name.setValue(this.AdressenInComponente.Rechnungsadresse.name)             
            }
            if (this.AdressenInComponente.Firmenadresse.adresszusatz != null ) {
             this.RechnungsAddressFormGroup.controls.adresszusatz.setValue(this.AdressenInComponente.Rechnungsadresse.adresszusatz)             
            }    
         }
        },
        (error)=> console.log(error)
      );
    //get Umweltchecks
    this.Dataservice.getUmweltChecks()
    .subscribe(
            (response : Umweltchecks)=>{              
              //speichere den Umweltcheck weg:
              this.Dataservice.updateUmweltchecks(response)             
              console.log("Http-Response in Kundeneingabe:", this.Dataservice.Umweltchecks)
            },
            (error)=>{
              console.log(error)
              if(error.status==405||410||302){
                this.authGuardService.deleteCurrentUser()
                }
              }
            )
    //Listen to saveKundeEvent vom Parentcomponent: eingabe.component => SAVE local Data!
    this.saveKunde.subscribe(
      ()=>{
        //speichere den geupdaten lokal ab! 
        this.Dataservice.saveKunde()},
      error=>{        
        if(error.status==405||410||302){
          this.authGuardService.deleteCurrentUser()
          }
      },
      complete=>{console.log("Kunde erfolgereich vom Frontent versendet")}
    )
    //Listen to save AdressenEvent vom Parentcomponent: eingabe.component
    //speichere die geupdaten lokal ab!
    this.saveAdressen.subscribe(
      ()=>{this.Dataservice.saveFirmenAdresse();
        if(this._SlideToggle_Rechnungsaddresse.checked){
          console.log("Save Rechnungsadresse")
          this.Dataservice.saveRechnungsAdresse()
        }else{          
          console.log("Delete Rechnungsaddresse")
          this.Dataservice.deleteRechnungsAdresse()
        }
        },
      error=>{
        console.log(error)
        if(error.status==405||410||302){
          this.authGuardService.deleteCurrentUser()
        }
      },
      complete=>{console.log("Kunde erfolgereich vom Frontent versendet")}
    )
    this.pruefeEingabe.subscribe(()=>{      
      this.pruefeKundeneingabe(), console.log("PrüfeKundeineingabe()")
    })
    //Listen to save Umweltcheck vom Parentcomponent: eingabe.component
    //speichere die geupdaten lokal ab!
    this.Dataservice.UmweltchecksBhobj.subscribe(
      (Umweltchecks)=>{
        this.pullcounter++
        if(this.pullcounter==1){
          this.onChanges()
        }
      }

    )
  }
  ngAfterViewInit() {
  }
  onChanges() : void {
    //this.name.valueChanges.subscribe(val=>{console.log("name valueChange läuft")})

    this.kontaktdatenFormGroup.valueChanges
    .subscribe(val=>{
      let Kunde2 = new Kunde()
      //unbedingt alte IDs mitnehmen!
      Kunde2.kunden_id = this.Dataservice.KundenBhobj.getValue().kunden_id
      Kunde2.firmen_adresse_id = this.Dataservice.KundenBhobj.getValue().firmen_adresse_id
      Kunde2.rechnungs_adresse_id = this.Dataservice.KundenBhobj.getValue().rechnungs_adresse_id
  
      Kunde2.name_des_betriebes = this.kontaktdatenFormGroup.controls.name_des_betriebes.value

      Kunde2.ansprechpartner = this.kontaktdatenFormGroup.controls.ansprechpartner.value      
      Kunde2.ansprechpartner_ansprache = this.kontaktdatenFormGroup.controls.ansprechpartner_ansprache.value
      Kunde2.ansprechpartner_email = this.kontaktdatenFormGroup.controls.ansprechpartner_email.value
      Kunde2.ansprechpartner_mobil = this.kontaktdatenFormGroup.controls.ansprechpartner_mobil.value
      Kunde2.ansprechpartner_telefon = this.kontaktdatenFormGroup.controls.ansprechpartner_telefon.value
      Kunde2.notiz = this.kontaktdatenFormGroup.controls.notiz.value
      
      console.log("Values Changed kontaktdatenFormGroup => works", val)

      this.Dataservice.updateKunde(Kunde2)
    });

    this.FirmenAddressFormGroup.valueChanges.subscribe(val => {        
        let Adressen2 = new Adressen() 
        //Adressen2.Firmenadresse.adress_id = this.AdressenInComponente.Firmenadresse.adress_id
        //Adressen2.Rechnungsadresse.adress_id = this.AdressenInComponente.Rechnungsadresse.adress_id

        //Firmenadresse
        Adressen2.Firmenadresse.name = this.FirmenAddressFormGroup.controls.name.value
        Adressen2.Firmenadresse.adresszusatz = this.FirmenAddressFormGroup.controls.adresszusatz.value
        Adressen2.Firmenadresse.strasse = this.FirmenAddressFormGroup.controls.strasse.value
        Adressen2.Firmenadresse.ort = this.FirmenAddressFormGroup.controls.ort.value
        Adressen2.Firmenadresse.postleitzahl = String(this.FirmenAddressFormGroup.controls.postleitzahl.value)
        Adressen2.Firmenadresse.land = this.FirmenAddressFormGroup.controls.land.value
        Adressen2.Firmenadresse.typ = "Firmenadresse" 
        Adressen2.Firmenadresse.UStID = this.FirmenAddressFormGroup.controls.UStID.value
                  
        console.log("Values Changed FirmenAddressFormGroup => works", val) 
        this.Dataservice.updateFirmenAdresse(Adressen2.Firmenadresse)
    });
    this.RechnungsAddressFormGroup.valueChanges.subscribe(val=>{
      let Adressen1 = new Adressen()
              //Rechnungsadresse
              Adressen1.Rechnungsadresse.name = this.RechnungsAddressFormGroup.controls.name.value
              Adressen1.Rechnungsadresse.adresszusatz = this.RechnungsAddressFormGroup.controls.adresszusatz.value
              Adressen1.Rechnungsadresse.strasse = this.RechnungsAddressFormGroup.controls.strasse.value
              Adressen1.Rechnungsadresse.ort = this.RechnungsAddressFormGroup.controls.ort.value
              Adressen1.Rechnungsadresse.postleitzahl = String(this.RechnungsAddressFormGroup.controls.postleitzahl.value)
              Adressen1.Rechnungsadresse.land = this.RechnungsAddressFormGroup.controls.land.value
              Adressen1.Rechnungsadresse.typ = "Rechnungsadresse"
              Adressen1.Rechnungsadresse.UStID = this.RechnungsAddressFormGroup.controls.UStID.value
              this.Dataservice.updateRechnungsAdresse(Adressen1.Rechnungsadresse)
    })


  }
  //dies Funktion packt die FormArray mit den zugehörigen Namen (=Fehlermeldungen)
  //in die Arrays und updated diese manuell (fuck this shit, warum nicht automatisch?)
  pruefeKundeneingabe(){
    //Array zusammenkloppen: "Name", "Error"
    if(this.kontaktdatenFormGroup.controls.name_des_betriebes.invalid){
      this.Pruefservice.pushNameErrorArray("Name des Betriebes",this.kontaktdatenFormGroup.controls.name_des_betriebes.errors)
    }
    if(this.kontaktdatenFormGroup.controls.kunden_nummer.invalid){
      this.Pruefservice.pushNameErrorArray("Kundennummer",this.kontaktdatenFormGroup.controls.kunden_nummer.errors)
    }
    if(this.kontaktdatenFormGroup.controls.ansprechpartner.invalid){
      this.Pruefservice.pushNameErrorArray("Name des Ansprechpartners",this.kontaktdatenFormGroup.controls.ansprechpartner.errors)
    }
    if(this.kontaktdatenFormGroup.controls.ansprechpartner_email.invalid){
      this.Pruefservice.pushNameErrorArray("Ansprechpartner E-Mail",this.kontaktdatenFormGroup.controls.ansprechpartner_email.errors)
    }
    if(this.kontaktdatenFormGroup.controls.ansprechpartner_mobil.invalid){
      this.Pruefservice.pushNameErrorArray("Ansprechpartner Mobil",this.kontaktdatenFormGroup.controls.ansprechpartner_mobil.errors)
    }
    if(this.kontaktdatenFormGroup.controls.ansprechpartner_telefon.invalid){
      this.Pruefservice.pushNameErrorArray("Ansprechpartner Telefon",this.kontaktdatenFormGroup.controls.ansprechpartner_telefon.errors)
    }
    if(this.kontaktdatenFormGroup.controls.notiz.invalid){
      this.Pruefservice.pushNameErrorArray("Notiz",this.kontaktdatenFormGroup.controls.notiz.errors)
    } 
    if(this.kontaktdatenFormGroup.controls.ansprechpartner_ansprache.invalid){
      this.Pruefservice.pushNameErrorArray("Anprechpartner Anrede",this.kontaktdatenFormGroup.controls.ansprechpartner_ansprache.errors)
    } 
    this.Pruefservice.pushInvalidFormgroup("Kontaktdaten",this.kontaktdatenFormGroup)

    
    /*
    name_des_betriebes: new FormControl('',[Validators.required]), //0
      kunden_nummer: new FormControl('',[]),                         //1
      ansprechpartner: new FormControl('',[Validators.required]),    //2
      ansprechpartner_email : new FormControl('', [Validators.required, Validators.email]), //3
      ansprechpartner_mobil : new FormControl('', []),               //4
      ansprechpartner_telefon : new FormControl('', [Validators.required]),    //5  
      notiz: new Form //6
    */
   if(this.FirmenAddressFormGroup.controls.name.invalid){
      this.Pruefservice.pushNameErrorArray("Firmenname:",this.FirmenAddressFormGroup.controls.name.errors)
   }
   if(this.FirmenAddressFormGroup.controls.strasse.invalid){
    this.Pruefservice.pushNameErrorArray("Straße",this.FirmenAddressFormGroup.controls.strasse.errors)
   }
   if(this.FirmenAddressFormGroup.controls.adresszusatz.invalid){
    this.Pruefservice.pushNameErrorArray("Adresszusatz",this.FirmenAddressFormGroup.controls.adresszusatz.errors)
   }
   if(this.FirmenAddressFormGroup.controls.postleitzahl.invalid){
    this.Pruefservice.pushNameErrorArray("Postleitzahl",this.FirmenAddressFormGroup.controls.postleitzahl.errors)
   }
   if(this.FirmenAddressFormGroup.controls.ort.invalid){
    this.Pruefservice.pushNameErrorArray("Ort",this.FirmenAddressFormGroup.controls.ort.errors)
   }
   if(this.FirmenAddressFormGroup.controls.land.invalid){
    this.Pruefservice.pushNameErrorArray("Land",this.FirmenAddressFormGroup.controls.land.errors)
   }
   if(this.FirmenAddressFormGroup.controls.UStID.invalid){
    this.Pruefservice.pushNameErrorArray("UStID: Umsatzsteueridentifikationsnummer",this.FirmenAddressFormGroup.controls.UStID.errors)
   }
   this.Pruefservice.pushInvalidFormgroup("Firmenanschrift", this.FirmenAddressFormGroup)
   /*      name: new FormControl('',[Validators.required]),    //0
      strasse: new FormControl('',[Validators.required]), //1
      adresszusatz: new FormControl('',[]),               //2
      postleitzahl: new FormControl('',[Validators.required]), //3
      ort: new FormControl('',[Validators.required]),     //4
      land : new FormControl('', []),                     //5
      UStID: new FormControl('', [Validators.required]),  //6 */
  if(this._SlideToggle_Rechnungsaddresse.checked){
    if(this.RechnungsAddressFormGroup.controls.checkedFormControl_Rechnungsadresse.invalid){
      this.Pruefservice.pushNameErrorArray("Rechnungsadresse ist gleich Firmenadresse",this.RechnungsAddressFormGroup.controls.checkedFormControl_Rechnungsadresse.errors)
    }
    if(this.RechnungsAddressFormGroup.controls.name.invalid){
      this.Pruefservice.pushNameErrorArray("Name",this.RechnungsAddressFormGroup.controls.name.errors)
    }
    if(this.RechnungsAddressFormGroup.controls.strasse.invalid){
      this.Pruefservice.pushNameErrorArray("Straße",this.RechnungsAddressFormGroup.controls.strasse.errors)
    }
    if(this.RechnungsAddressFormGroup.controls.adresszusatz.invalid){
      this.Pruefservice.pushNameErrorArray("Adresszusatz",this.RechnungsAddressFormGroup.controls.adresszusatz.errors)
    }
    if(this.RechnungsAddressFormGroup.controls.postleitzahl.invalid){
      this.Pruefservice.pushNameErrorArray("Postleitzahl",this.RechnungsAddressFormGroup.controls.postleitzahl.errors)
    }
    if(this.RechnungsAddressFormGroup.controls.ort.invalid){
      this.Pruefservice.pushNameErrorArray("Ort",this.RechnungsAddressFormGroup.controls.ort.errors)
    }
    if(this.RechnungsAddressFormGroup.controls.land.invalid){
      this.Pruefservice.pushNameErrorArray("Land",this.RechnungsAddressFormGroup.controls.land.errors)
    }
    if(this.RechnungsAddressFormGroup.controls.UStID.invalid){
      this.Pruefservice.pushNameErrorArray("UStID - Umsatzsteueridentifikationnummer",this.RechnungsAddressFormGroup.controls.UStID.errors)
    }
    this.Pruefservice.pushInvalidFormgroup("Rechnungsanschrift",this.RechnungsAddressFormGroup)
  }
  /*
        checkedFormControl_Rechnungsadresse : new FormControl('',[Validators.required]), //0
      name: new FormControl('',[Validators.required]),                                 //1
      strasse: new FormControl('',[Validators.required]),                              //2
      adresszusatz: new FormControl('',[Validators.nullValidator]),                    //3
      postleitzahl: new FormControl('',[Validators.required]),                         //4
      ort: new FormControl('',[Validators.required]),                                  //5
      land : new FormControl('', [Validators.required]),                               //6
      UStID: new FormControl('', [Validators.required]),                               //7
   */
  }
}

