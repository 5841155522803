export class User {
    id: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    KundenID : number;
    zustimmung_newsletter  : boolean;
    zustimmung_ds          : boolean;
    zustimmung_newsletter_datum :Date;
    zustimmung_ds_datum : Date;
}