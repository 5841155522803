import { Component } from '@angular/core';
import { Router } from '@angular/router';


import { AlertService, UserService } from '../_services/index';

@Component({
    selector: 'app-register',
    moduleId: module.id.toString(),
    templateUrl: 'register.component.html'
})

export class RegisterComponent {
    model: any = {};
    loading = false;

    constructor(
        private router: Router,
        private userService: UserService,
        private alertService: AlertService) { }

    register() {
        this.loading = true;
        this.userService.create(this.model)
            .subscribe(
                data => {
                    this.alertService.success('Registrierung erfolgreich', true);
                    this.router.navigate(['/login']);
                },
                error => {
                    if(error.status==409){
                        error="Diese Emailaddresse ist schon vergeben. Bitte wählen Sie eine andere."
                    }
                    this.alertService.error(error);
                    this.loading = false;

                });
    }
}
