
import { Einrichtung } from '../_models/einrichtung'
export class Umweltcheck {
    umweltcheck_id   : number         
	einrichtungs_id  : number      
	kunden_id        : number 
	erstellungdatum  : number 
	umweltcheck_name : string      
    co2_faktoren_id  : number 
    berechnet : boolean
    Einrichtung: Einrichtung


    
    constructor(){
        this.umweltcheck_id=0
        this.einrichtungs_id=0
        this.kunden_id=0
        this.erstellungdatum =  Date.now()
        this.umweltcheck_name = ''
        this.co2_faktoren_id = 1
        this.berechnet = false
    }
}