import { Component, OnInit , EventEmitter, Input, ViewChild} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Wellnessbereich } from '../_models/wellnessbereich';
import { PruefService } from '../_services/pruefservice.service';
import { DataService } from '../_services/data.service';
import * as Rx from "rxjs";
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-wasser',
  templateUrl: './wasser.component.html',
  styleUrls: ['./wasser.component.css']
})
export class WasserComponent implements OnInit {
  @Input() saveWasser : EventEmitter<void>; 
  @Input() pruefeEingabe : EventEmitter<void>;
  matcher : ErrorStateMatcher;
  @ViewChild('w', { static: true }) _SlideToggle_wellness: MatSlideToggle;
  
  WasserFormGroup:FormGroup; 
  wellnessbereichFormGroup:FormGroup;

  selected_oeffnungswochen : number;
  Wellnessbereich : Wellnessbereich;

  umweltcheckSubscription: Rx.Subscription;
  kalenderwochen: number[];
  pullcounter:number;

  constructor(private Dataservice : DataService, private Pruefservice : PruefService) {
    this.matcher = new ErrorStateMatcher(); 
    this.Wellnessbereich = new Wellnessbereich();
    this.kalenderwochen = Array(52).map((x,i)=>i)

    this.WasserFormGroup = new FormGroup({ 
      wasserverbrauch_in_m3  :  new FormControl('', [Validators.required])
    })
    this.wellnessbereichFormGroup = new FormGroup({
      checkedFormControl  :new FormControl() ,
      groesse_in_m2 :new FormControl('', [Validators.required]),
      anzahl_der_saunen : new FormControl('', [Validators.required, Validators.pattern("^[0-9]*")]),
      volumen_schwimmbad_in_m3 :new FormControl('', [Validators.required]),
      Oeffnungswochen_pro_jahr :new FormControl('', [Validators.required, Validators.min(1)])
    })
    this.pullcounter=0
  }
  ngOnInit() {    
    this.Dataservice.UmweltchecksBhobj.subscribe(
      //Problem: wird einmal aufgerufnen beim Konstruktor und einmal, wenn die Datengeupdated werden.
      (Umweltchecks) => {
      console.log("Wasser-COMPONENTE:",Umweltchecks, "Pullcounter:", this.pullcounter); 
      if(Umweltchecks.Umweltchecks){
        this.pullcounter++
        if(this.pullcounter==1){
          this.pullDatainWasserComponente()
        } 
      }
    },
      (error) => {console.log("ERROR in Wassercomponente.", error)}
    )
    //Listen to saveBetriebsdaten-Event vom Parentcomponent: eingabe.component => SAVE local Data!
    this.saveWasser.subscribe(
      ()=>{
        //lokal: schreibe die Werte in die Tonnen, dann in den Dataservice -
        this.saveWasserInDataservice()},
      err=>{console.log(err)},
      complete=>{console.log("Wasser erfolgereich vom Frontent versendet")}
    )
    this.pruefeEingabe.subscribe(()=>{
      this.pruefEingabeDerWasserComponente()
    })
  }
  pullDatainWasserComponente(){
    this.WasserFormGroup.controls.wasserverbrauch_in_m3.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.wasserverbrauch_in_m3)
      
    //die Einrichtung hat einen Wellnessbereich, wenn Werte exisitieren:
    if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich
      &&(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich.groesse_in_m2>0||
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich.anzahl_der_saunen>0||
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich.wellnessbereich_id>0
        )
      ){      
      //eine Sekunde warten, weil ng-if das erst in die DOM laden muss #AngularFail
      setTimeout(() => {    
        this.wellnessbereichFormGroup.controls.groesse_in_m2.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich.groesse_in_m2)
        this.wellnessbereichFormGroup.controls.volumen_schwimmbad_in_m3.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich.volumen_schwimmbad_in_m3)
        this.wellnessbereichFormGroup.controls.anzahl_der_saunen.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich.anzahl_der_saunen)
        this.wellnessbereichFormGroup.controls.Oeffnungswochen_pro_jahr.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich.Oeffnungswochen_pro_jahr)
        console.log("BETRIEBSDATEN: Oeffnungswochen pro Jahr:",this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich.Oeffnungswochen_pro_jahr)
        this.selected_oeffnungswochen=this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich.Oeffnungswochen_pro_jahr
        //this.getOeffnungswochen_pro_jahr(this.wellnessbereichFormGroup.controls.Oeffnungswochen_pro_jahr.value)
        // warum nimmt er nicht den richtigen Value?
        //Toggle umstellen
        this._SlideToggle_wellness.toggle()
      }, 500);
    }
  }
  saveWasserInDataservice(){
    //test
    this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.wasserverbrauch_in_m3=Number(this.WasserFormGroup.controls.wasserverbrauch_in_m3.value)
   
    if(this._SlideToggle_wellness.checked){ //toggle Wellnessbereich checked
      //die Einrichtung hat einen Wellnessbereich, wenn Werte exisitieren:
      //update alle Felder Wellnessbereich oder lege an ,if null:
      //Wenn der getoggle ist: update, wenn nicht, lösche die WellnessbereichID im Parent?
      //UPDATE Wellnessbereich: 
        if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich){ 
          this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich.groesse_in_m2=Number(this.wellnessbereichFormGroup.controls.groesse_in_m2.value)
          this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich.volumen_schwimmbad_in_m3=Number(this.wellnessbereichFormGroup.controls.volumen_schwimmbad_in_m3.value)
          this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich.anzahl_der_saunen=Number(this.wellnessbereichFormGroup.controls.anzahl_der_saunen.value)
          this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich.Oeffnungswochen_pro_jahr=Number(this.wellnessbereichFormGroup.controls.Oeffnungswochen_pro_jahr.value)
          this.Dataservice.saveWellnessbereich()
        }else{
          //lege neuen an Wellnessbereich an:
          //nutze lokalen Wellnessberich     
          
            this.Wellnessbereich.groesse_in_m2=Number(this.wellnessbereichFormGroup.controls.groesse_in_m2.value)
            this.Wellnessbereich.volumen_schwimmbad_in_m3=Number(this.wellnessbereichFormGroup.controls.volumen_schwimmbad_in_m3.value)
            this.Wellnessbereich.Oeffnungswochen_pro_jahr=Number(this.wellnessbereichFormGroup.controls.Oeffnungswochen_pro_jahr.value)
            this.Wellnessbereich.anzahl_der_saunen=Number(this.wellnessbereichFormGroup.controls.anzahl_der_saunen.value)
            
            //füge in den Dataservice ein:
            this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich = this.Wellnessbereich
            this.Dataservice.saveWellnessbereich()
        }
      }else{//toggle auf Off  
        //setze den Wellnessbereich auf null
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Wellnessbereich = null

        //lösche den Wellnessbereich auf dem Server
        this.Dataservice.deleteWellnessbereich()
      }
      //schicke Request ab Wasser steht da drin 
      this.Dataservice.saveEinrichtung()
      //jetzt Behavoir Object updaten: 
      this.Dataservice.UmweltchecksBhobj.next(this.Dataservice.Umweltchecks)
  }
  pruefEingabeDerWasserComponente(){
    if(this.WasserFormGroup.controls.wasserverbrauch_in_m3.invalid)
       this.Pruefservice.pushNameErrorArray("Wasserverbrauch in m³",this.WasserFormGroup.controls.wasserverbrauch_in_m3.errors)
    this.Pruefservice.pushInvalidFormgroup("Wasser",this.WasserFormGroup)

    if(this._SlideToggle_wellness.checked){
      //Hotel: Du bist kleiner als 0, ich hau Dich !      
      if((Number(this.wellnessbereichFormGroup.controls.groesse_in_m2.value)<=0)&&Number(this.Dataservice.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.hotel_id)>0)
        this.Pruefservice.pushNameErrorArray("Größe in m²",this.wellnessbereichFormGroup.controls.groesse_in_m2.errors)
      //FeWo: Du darfst nur nicht kleiner als 0 sein
      if((Number(this.wellnessbereichFormGroup.controls.groesse_in_m2.value)<0)&&Number(this.Dataservice.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.ferienwohnungseinrichtungs_id)>0)
      this.Pruefservice.pushNameErrorArray("Größe in m²",this.wellnessbereichFormGroup.controls.groesse_in_m2.errors)
      if(this.wellnessbereichFormGroup.controls.anzahl_der_saunen.invalid)
        this.Pruefservice.pushNameErrorArray("Anzahl der Saunen",this.wellnessbereichFormGroup.controls.anzahl_der_saunen.errors)
      if(this.wellnessbereichFormGroup.controls.volumen_schwimmbad_in_m3.invalid)
        this.Pruefservice.pushNameErrorArray("Volumen Schwimmbad in m³",this.wellnessbereichFormGroup.controls.volumen_schwimmbad_in_m3.errors)
      if(this.wellnessbereichFormGroup.controls.Oeffnungswochen_pro_jahr.invalid)
        this.Pruefservice.pushNameErrorArray("Öffnungswochen pro Jahr",this.wellnessbereichFormGroup.controls.Oeffnungswochen_pro_jahr.errors)
      this.Pruefservice.pushInvalidFormgroup("Wellnessbereich",this.wellnessbereichFormGroup)
    }
  /*this.wellnessbereichFormGroup = new FormGroup({
      checkedFormControl  :new FormControl() ,
      groesse_in_m2 :new FormControl('', [Validators.required]),
      anzahl_der_saunen : new FormControl('', [Validators.required]),
      volumen_schwimmbad_in_m3 :new FormControl('', [Validators.required]),
      Oeffnungswochen_pro_jahr :new FormControl('', [Validators.required,Validators.min(0)])
    })*/
  }
  getOeffnungswochen_pro_jahr(valueOption: any, valueSelection: any):boolean{  
    if(this.wellnessbereichFormGroup && this.wellnessbereichFormGroup.controls.Oeffnungswochen_pro_jahr.value ){
      console.log("FUNKTIONSAUFRUFFFFFFFFF")
      return valueOption === valueSelection
    }
    return false
  }
}
