import { Component, OnInit} from '@angular/core';
import { faCheck, faBuilding, faRuler ,faAward,faDirections,faCompass, faUserEdit,faCloudUploadAlt, faEuroSign,faChartBar,faEnvelope,faHome,faInfoCircle,faSync,faChartLine, faEdit,faLeaf,faCalculator,faIndustry, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css']
})
export class LandingpageComponent implements OnInit {
  readMore : boolean
  readMore1 : boolean
  faCheck = faCheck;
  faBuilding=faBuilding;
  faRuler=faRuler;
  faAward=faAward;
  faCompass=faCompass;
  faDirections=faDirections;
  faUserEdit=faUserEdit;
  faCloudUploadAlt=faCloudUploadAlt;
  faEuroSign=faEuroSign;
  faChartBar=faChartBar;
  faEnvelope=faEnvelope;
  faHome=faHome;
  faInfoCircle=faInfoCircle;
  faSync=faSync;
  faChartLine=faChartLine;
  faEdit=faEdit;
  faLeaf=faLeaf;
  faCalculator=faCalculator;
  faIndustry=faIndustry;
  faExchangeAlt=faExchangeAlt;


  constructor() {
    this.readMore = false;
    this.readMore1= false;    
   }

  ngOnInit() {
  }

}
