import { Component, OnInit , EventEmitter, Input} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PruefService } from '../_services/pruefservice.service';
import { DataService } from '../_services/data.service';
import * as Rx from "rxjs";
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-strom',
  templateUrl: './strom.component.html',
  styleUrls: ['./strom.component.css']
})
export class StromComponent implements OnInit {
  @Input() saveStrom : EventEmitter<void>; 
  @Input() pruefeEingabe : EventEmitter<void>;
  StromFormGroup : FormGroup;
  matcher : ErrorStateMatcher;
  
  umweltcheckSubscription: Rx.Subscription;
  pullcounter : number

  constructor(private Dataservice : DataService, private Pruefservice : PruefService) { 
    this.matcher = new ErrorStateMatcher();
    this.StromFormGroup = new FormGroup({ 
      stromverbrauch_in_kwh :  new FormControl('', [Validators.required,Validators.min(1)]) ,
      oekostromverbrauch_in_kwh :  new FormControl('', [Validators.required,Validators.min(0.000000),Validators.max(100),Validators.maxLength(3),Validators.minLength(0.0000)]),
    })
    this.pullcounter=0
  }
  ngOnInit() {
    this.Dataservice.UmweltchecksBhobj.subscribe(
      //Problem: wird einmal aufgerufnen beim Konstruktor und einmal, wenn die Datengeupdated werden.
      (Umweltchecks) => {console.log("Strom-COMPONENTE:",Umweltchecks); 
      if(Umweltchecks.Umweltchecks){
        this.pullcounter++
        if(this.pullcounter==1){
          this.pullDataInStromComponente() 
        }
      }
    },
      (error) => {console.log("ERROR in Stromcomponente.", error)}
    )
    this.saveStrom.subscribe(
      ()=>{
        this.saveStromInDataservice()},
      err=>{console.log(err)},
      complete=>{console.log("Strom erfolgereich vom Frontent versendet")}
    )
    this.pruefeEingabe.subscribe(()=>{
      this.pruefeStromComponente()
    })
  }
  pullDataInStromComponente(){
    if(this.Dataservice.Umweltchecks.Umweltchecks.length>0){
        this.StromFormGroup.controls.stromverbrauch_in_kwh.setValue( Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.stromverbrauch_in_kwh)+Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.oekostromverbrauch_in_kwh))
        this.StromFormGroup.controls.oekostromverbrauch_in_kwh.setValue((Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.oekostromverbrauch_in_kwh))/(Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.stromverbrauch_in_kwh)+Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.oekostromverbrauch_in_kwh))*100)
    }
  }
  saveStromInDataservice(){
    if(this.Dataservice.Umweltchecks.Umweltchecks.length>0){
      //UPDATE Einrichtung:
      //im Backend wird immer zusamment mit einem Umweltcheck eine Einrichtung angelegt
      //deswegen können wir die Einrichtung immer Updaten und müssen Sie nicht neu anlegen
      this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.oekostromverbrauch_in_kwh=Number(this.StromFormGroup.controls.oekostromverbrauch_in_kwh.value)*Number(this.StromFormGroup.controls.stromverbrauch_in_kwh.value)/100
      this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.stromverbrauch_in_kwh=Number(this.StromFormGroup.controls.stromverbrauch_in_kwh.value)-this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.oekostromverbrauch_in_kwh
      this.Dataservice.saveEinrichtung()
    }
  }
  pruefeStromComponente(){
    if(this.StromFormGroup.controls.stromverbrauch_in_kwh.invalid)
      this.Pruefservice.pushNameErrorArray("Stromverbrauch in kWh",this.StromFormGroup.controls.stromverbrauch_in_kwh.errors)
    if(this.StromFormGroup.controls.oekostromverbrauch_in_kwh.invalid)
      this.Pruefservice.pushNameErrorArray("Ökostrom in %",this.StromFormGroup.controls.oekostromverbrauch_in_kwh.errors)    
    
    this.Pruefservice.pushInvalidFormgroup("Strom",this.StromFormGroup)
  }
}
