import { Component, OnInit, Input, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { DataService } from '../_services/data.service'
import { Umweltchecks, Umweltcheck } from '../_models';
import { FeWo } from '../_models/FeWo';
import { Hotel } from '../_models/hotel';
import * as Rx from "rxjs";
import { begleitfrage } from '../_models/begleitfrage';
import { PruefService } from '../_services/pruefservice.service';


@Component({
  selector: 'app-betriebsdaten',
  templateUrl: './betriebsdaten.component.html',
  styleUrls: ['./betriebsdaten.component.css']
})

export class BetriebsdatenComponent implements OnInit {
  //Eventlistener zum Speichern: die Komponente hört drauf
  @Input() saveBetriebsdaten : EventEmitter<void>;  
  @Input() pruefeEingabe : EventEmitter<void>;

  
  @ViewChild('p', { static: true }) _SlideToggle_privat: MatSlideToggle; 
  @ViewChild('f', { static: false }) _SlideToggle_flaecheEnthalten: MatSlideToggle;

  @ViewChild('pv', { static: false }) _SlideToggle_privateVerbraeucheSelberZaehler: MatSlideToggle;
  @ViewChild('privatStrom', { static: false }) _SlideToggle_privatStrom: MatSlideToggle;
  @ViewChild('privatWasser', { static: false }) _SlideToggle_privatWasser: MatSlideToggle;
  @ViewChild('privatHeizung', { static: false }) _SlideToggle_privatHeizung: MatSlideToggle;
  @ViewChild('privatRestmuell', { static: false }) _SlideToggle_privatRestmuell: MatSlideToggle;

  @ViewChild('bg', { static: true }) _SlideToggle_begleitfrageLED: MatSlideToggle;
  @ViewChild('sterne', { static: true }) _SlideToggle_Sterne: MatSlideToggle;
  @ViewChild('garni', { static: false }) _SlideToggle_garni: MatSlideToggle;
  valuePrivat = false;
  valueFlacheInGesamtflaeche=false;

  matcher : ErrorStateMatcher;

  selected :string
  anzahl_der_einrichtungen_value = '1'

  betriebsdatenFormGroup : FormGroup;
  privatFormGroup: FormGroup;
  FeWoFormGroup:FormGroup;
 
  kalenderwochen: number[];
  nummer_anzahl_privater_bewohner : number[];

  pullcounter:number


  //Sternche für die Sternebewertung
  color='primary';
  checked = false;
  checked2 = false;  
  //STAR RATING
  title = 'Star Rating';  
  starList: boolean[] = [true,true,true,true,true];       // create a list which contains status of 5 stars
  rating:number;  

  umweltcheckSubscription: Rx.Subscription;
  Nummer_Anzahl_der_Einrichtungen :  number[];


  constructor(private Dataservice : DataService, private Pruefservice : PruefService) {    
    this.nummer_anzahl_privater_bewohner=Array(500).map((x,i)=>i)
    this.Nummer_Anzahl_der_Einrichtungen=Array(500).map((x,i)=>i)
    this.selected = 'Hotels'
    this.pullcounter=0

    this.anzahl_der_einrichtungen_value = '1';
    this.betriebsdatenFormGroup = new FormGroup({
      anzahl_bettenuebernachtungen_pro_jahr  :  new FormControl('', [Validators.required, Validators.min(1)]),
      anzahl_der_einrichtungen  :  new FormControl('', [Validators.required, Validators.min(1)]),
      beheizte_gesamtflaeche_in_m3  :  new FormControl('', [Validators.required, Validators.min(1)]),
      davon_privatwohnflaeche_in_m2  :  new FormControl('', [Validators.required]), 
      eigenwaesche_in_kg  :  new FormControl('', [Validators.required]),
      anzahl_mahlzeiten_im_jahr  :  new FormControl('', [Validators.required,Validators.min(1)]), 
      anteil_mehrgaenge_menues :  new FormControl('', [Validators.required]), 
      abfall_in_kg_pro_jahr  :  new FormControl('', [Validators.required]) ,
      checkedFormControl_sterne :  new FormControl('', [Validators.required]),
      checkedFormControl9_garni :  new FormControl('', [Validators.required]),
      anzahl_restaurantsitzplaetze :  new FormControl('', [Validators.required,Validators.pattern("^[0-9]*")]),
      tagungsraumflaeche :  new FormControl('', [Validators.required,Validators.pattern("^[0-9]*")]),
    })
    this.FeWoFormGroup = new FormGroup({ 
      anzahl_der_gaesteanreisen_pro_jahr:  new FormControl('', [Validators.required,Validators.min(1)]), //keine Kommazahl
      anzahl_der_uebernachtungen_mai_bis_oktober:  new FormControl('', [Validators.required,Validators.min(1)]),
      anzahl_der_uebernachtungen_november_bis_april:  new FormControl('', [Validators.required,Validators.min(1)]),
      durchschnittliche_groesse_der_fe_wo_in_m2:  new FormControl('', [Validators.required,Validators.min(1)]),
      durchschnittliche_maximale_belegungszahl_der_fe_wo:  new FormControl('', [Validators.required,Validators.min(1)]),
    })
    this.privatFormGroup = new FormGroup({
      checkedFormControl2 : new FormControl('', [Validators.required]),
      anzahl_privater_bewohner  :  new FormControl('', [Validators.required,Validators.pattern("^[0-9]*")]),
      DavonPrivatwohnflaecheInM2 : new FormControl('', [Validators.required]),
      checkedFormControl3 : new FormControl('', [Validators.required]),
      checkedFormControl4_privateVerbraeuche :  new FormControl('', [Validators.required]),
      checkedFormControl5_privateVerbraeucheStrom :  new FormControl('', [Validators.required]),
      checkedFormControl6_privateVerbraeucheHeizung :  new FormControl('', [Validators.required]),
      checkedFormControl7_privateVerbraeucheWasser :  new FormControl('', [Validators.required]),
      checkedFormControl8_privateVerbraeucheRestmuell :  new FormControl('', [Validators.required]),
      checkedFormControl5_led :  new FormControl('', [Validators.required]),
    })
    this.matcher = new ErrorStateMatcher();

    this.kalenderwochen = Array(52).map((x,i)=>i)
    this.nummer_anzahl_privater_bewohner = Array(25).map((x,i)=>i)
  }

  ngOnInit() {
    //listen auf dem Umweltcheckobservable und hol das Zeug da raus: 
    // Call subscribe() to start listening for updates.    

  this.Dataservice.UmweltchecksBhobj.subscribe(
      //Problem: wird einmal aufgerufnen beim Konstruktor und einmal, wenn die Datengeupdated werden.
      (Umweltchecks) => {console.log("BETRIEBSDATENCOMPONENTE:",Umweltchecks); 
      if(Umweltchecks.Umweltchecks){
        this.pullcounter++
        if(this.pullcounter==1){
          this.pullDatainBetriebsdatenInBetriebsdatenComponente()
        }  
      }
    },
      (error) => {console.log("ERROR in Betriebsdatencomponente.", error)}
    )
    //Listen to saveBetriebsdaten-Event vom Parentcomponent: eingabe.component => SAVE local Data!
    this.saveBetriebsdaten.subscribe(
      ()=>{
        //lokal: schreibe die Werte in die Tonnen, dann in den Dataservice -
        this.saveBetriebsdatenInDataservice()},
      err=>{console.log(err)},
      complete=>{console.log("Betriebsdaten erfolgereich vom Frontent versendet")}
    )
    this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_mai_bis_oktober.valueChanges.subscribe(()=>{
      var BÜNproJahr =Number(this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_mai_bis_oktober.value)+Number(this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_november_bis_april.value)
      this.betriebsdatenFormGroup.controls.anzahl_bettenuebernachtungen_pro_jahr.setValue(BÜNproJahr)
    })
    this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_november_bis_april.valueChanges.subscribe(()=>{ 
      var BÜNproJahr =Number(this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_mai_bis_oktober.value)+Number(this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_november_bis_april.value)
      this.betriebsdatenFormGroup.controls.anzahl_bettenuebernachtungen_pro_jahr.setValue(BÜNproJahr)
    })
    this.pruefeEingabe.subscribe(()=>{
      if(this.selected=="Ferienwohnungen"){
        this.pruefeEingabeDerBetriebsdatencomponente_FeWo()
      }
      if(this.selected=="Hotels"){
        this.pruefeEingabeDerBetriebsdatencomponente_Hotel()
      }
    })
  }
  pullDatainBetriebsdatenInBetriebsdatenComponente(){    
    if(this.Dataservice.Umweltchecks.Umweltchecks.length>0){
      if(this.pullcounter==1){
        //Werte reinschmeißen aus dem Dataservice:
        this.betriebsdatenFormGroup.controls.anzahl_bettenuebernachtungen_pro_jahr.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_bettenuebernachtungen_pro_jahr)
        this.betriebsdatenFormGroup.controls.anzahl_der_einrichtungen.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_der_einrichtungen)
        this.betriebsdatenFormGroup.controls.beheizte_gesamtflaeche_in_m3.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.beheizte_gesamtflaeche_in_m3)
        
        this.betriebsdatenFormGroup.controls.davon_privatwohnflaeche_in_m2.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.davon_privatwohnflaeche_in_m2)
        this.betriebsdatenFormGroup.controls.eigenwaesche_in_kg.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.eigenwaesche_in_kg)
        this.betriebsdatenFormGroup.controls.anzahl_mahlzeiten_im_jahr.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_mahlzeiten_im_jahr)
        this.betriebsdatenFormGroup.controls.anteil_mehrgaenge_menues.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anteil_mehrgaenge_menues)
        
        this.betriebsdatenFormGroup.controls.tagungsraumflaeche.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.tagungsraumflaeche)
        this.betriebsdatenFormGroup.controls.anzahl_restaurantsitzplaetze.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_restaurantsitzplaetze)
        

        this.betriebsdatenFormGroup.controls.abfall_in_kg_pro_jahr.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.abfall_in_kg_pro_jahr)
      //console.log("ANZAHL DER STERNE: ",Math.abs(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_der_sterne))
        this.setStar(Math.abs(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_der_sterne)-1)
        this.betriebsdatenFormGroup.controls.checkedFormControl_sterne.setValue(Math.abs(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_der_sterne))
        if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_der_sterne<0){
          this._SlideToggle_Sterne.toggle()
        }   
        
        //Ferienwohnung: Wenn vorhanden:
        if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo){
          this.FeWoFormGroup.controls.anzahl_der_gaesteanreisen_pro_jahr.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo.anzahl_der_gaesteanreisen_pro_jahr)
          this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_mai_bis_oktober.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo.anzahl_der_uebernachtungen_mai_bis_oktober)
          this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_november_bis_april.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo.anzahl_der_uebernachtungen_november_bis_april)
          this.FeWoFormGroup.controls.durchschnittliche_groesse_der_fe_wo_in_m2.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo.durchschnittliche_groesse_der_fe_wo_in_m2)
          this.FeWoFormGroup.controls.durchschnittliche_maximale_belegungszahl_der_fe_wo.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo.durchschnittliche_maximale_belegungszahl_der_fe_wo)
          this.selected='Ferienwohnungen'
        } 
        //Hotel: wenn Du eins bist, dann
        if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.hotel_id>0){
          this.selected='Hotels'
          //Garni: Ja / Nein?
          if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Hotel){
            if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Hotel.hotel_kategorie=="garni"){
              this._SlideToggle_garni.toggle()
            }        
          }
        }
      }
      }
      //Privatpersonen:
      if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_privater_bewohner>0)
      {
        this._SlideToggle_privat.toggle()
        //eine Sekunde warten, weil ng-if das erst in die DOM laden muss #AngularFail
        setTimeout(() => {   
          this.privatFormGroup.controls.anzahl_privater_bewohner.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_privater_bewohner)
          this.privatFormGroup.controls.DavonPrivatwohnflaecheInM2.setValue(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.davon_privatwohnflaeche_in_m2)
          
          //der Toggle wird durch ngIf an und ausgeschaltet:
          //dadurch ist _SlideToggle_privateVerbraeucheSelberZaehler undefinded, wenn 
          //wohnen da private nicht getoggled ist
            this._SlideToggle_privateVerbraeucheSelberZaehler.toggleChange.subscribe(val => {
              this._SlideToggle_privatHeizung.toggle()
              this._SlideToggle_privatWasser.toggle()
              this._SlideToggle_privatStrom.toggle()
              this._SlideToggle_privatRestmuell.toggle()
            });
          },1500)

      //private verbraeuche:
      if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_heizungszaehler||
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_stromzaehler||
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_wasserzaehler||
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_restmuell){
          //es gibt mindestens einen gemeinsamen zaehler: 
          //dann toggle den Haupttoggle:
          setTimeout(()=>{this._SlideToggle_privateVerbraeucheSelberZaehler.toggle()},500)          
          
          setTimeout(()=>{  
          if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_heizungszaehler)
          {//gemeinsamen Hiezungs
            this._SlideToggle_privatHeizung.toggle()
          }
          if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_stromzaehler)
          {//gemeinsamen Hiezungs
            this._SlideToggle_privatStrom.toggle()
          }
          if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_wasserzaehler)
          {//gemeinsamen Hiezungs
            this._SlideToggle_privatWasser.toggle()
          }
          if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_restmuell)
          {//gemeinsamen Hiezungs
            this._SlideToggle_privatRestmuell.toggle()
          }      
          },500)
        }
      

      //begleitfrage LED
      var begleitfrageLED = this.Dataservice.getbegleitfrageAusbegleitfrageArray('LED')
      if(begleitfrageLED[0]){
        if(begleitfrageLED[0].antwort)
                this._SlideToggle_begleitfrageLED.toggle()
      }  
    }
  }
  //Create a function which receives the value counting of stars click, 
  //and according to that value we do change the value of that star in list.
  setStar(data:any){
        this.rating=data+1;                               
        for(var i=0;i<=4;i++){  
          if(i<=data){  
            this.starList[i]=false;  
          }  
          else{  
            this.starList[i]=true;  
          }  
       }  
   }
saveBetriebsdatenInDataservice(){
  if(this.Dataservice.Umweltchecks.Umweltchecks.length>0){
    //UPDATE Einrichtung:
    //im Backend wird immer zusamment mit einem Umweltcheck eine Einrichtung angelegt
    //deswegen können wir die Einrichtung immer Updaten und müssen Sie nicht neu anlegen
    console.log("SaveBetriebsdatenInDataservice:",this.betriebsdatenFormGroup.controls.anzahl_bettenuebernachtungen_pro_jahr.value)
    
    //wenn der privat Toggle nicht getoggelt ist, musst Du die Werte nullen:
    if(!this._SlideToggle_privat.checked){
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.davon_privatwohnflaeche_in_m2=Number(0)
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_privater_bewohner=Math.round(Number(0))
    }else{
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.davon_privatwohnflaeche_in_m2=Number(this.privatFormGroup.controls.DavonPrivatwohnflaecheInM2.value)
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_privater_bewohner=Math.round(Number(this.privatFormGroup.controls.anzahl_privater_bewohner.value))    
    }
    this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_bettenuebernachtungen_pro_jahr = Math.round(Number(this.betriebsdatenFormGroup.controls.anzahl_bettenuebernachtungen_pro_jahr.value))
    this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_der_einrichtungen=Number(this.betriebsdatenFormGroup.controls.anzahl_der_einrichtungen.value)
    this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.beheizte_gesamtflaeche_in_m3=Number(this.betriebsdatenFormGroup.controls.beheizte_gesamtflaeche_in_m3.value)
    this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.eigenwaesche_in_kg=Number(this.betriebsdatenFormGroup.controls.eigenwaesche_in_kg.value)
    this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_mahlzeiten_im_jahr=Math.round(Number(this.betriebsdatenFormGroup.controls.anzahl_mahlzeiten_im_jahr.value))
    this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anteil_mehrgaenge_menues=Number(this.betriebsdatenFormGroup.controls.anteil_mehrgaenge_menues.value)
    this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_restaurantsitzplaetze=Math.round(Number(this.betriebsdatenFormGroup.controls.anzahl_restaurantsitzplaetze.value))
    this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.abfall_in_kg_pro_jahr=Number(this.betriebsdatenFormGroup.controls.abfall_in_kg_pro_jahr.value)
    this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.tagungsraumflaeche=Number(this.betriebsdatenFormGroup.controls.tagungsraumflaeche.value)
    
    //FALL: Hotel
    if(this.selected=='Hotels'){
      var hotel = new Hotel()
      if(this._SlideToggle_garni && this._SlideToggle_garni.checked){
        hotel.hotel_kategorie="garni"
      }else{
        hotel.hotel_kategorie=""
      }      
      this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Hotel=hotel;  
      this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo=null
      this.Dataservice.saveHotel()
      console.log("Hotel: save Hotel")
    }
    
    if(this._SlideToggle_Sterne.checked){ //ja
       this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_der_sterne=this.rating
    }else{ //nein
      this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_der_sterne=this.rating*(-1)
    }
 
    //lege eine an, wenn ausgewaehlt:
    if(this.selected=='Ferienwohnungen'){
        //lösche das Hotel am Datensatz
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Hotel=null
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.hotel_id=null
        
        //UPDATE Ferienwohnungseinrichtung oder lege an    
        if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo){
          this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo.anzahl_der_ferienwohnungen=Math.round(Number(this.betriebsdatenFormGroup.controls.anzahl_der_einrichtungen.value))
          this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo.anzahl_der_gaesteanreisen_pro_jahr=Math.round(Number(this.FeWoFormGroup.controls.anzahl_der_gaesteanreisen_pro_jahr.value))
          this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo.anzahl_der_uebernachtungen_november_bis_april=Math.round(Number(this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_november_bis_april.value))
          this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo.anzahl_der_uebernachtungen_mai_bis_oktober=Math.round(Number(this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_mai_bis_oktober.value))
          this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo.durchschnittliche_groesse_der_fe_wo_in_m2=Number(this.FeWoFormGroup.controls.durchschnittliche_groesse_der_fe_wo_in_m2.value)
          this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo.durchschnittliche_maximale_belegungszahl_der_fe_wo=Number(this.FeWoFormGroup.controls.durchschnittliche_maximale_belegungszahl_der_fe_wo.value)
          this.Dataservice.saveFeWo()
        }else{
        //lege neue an: 
          var FeWoh=new FeWo()
          FeWoh.anzahl_der_ferienwohnungen=Number(this.betriebsdatenFormGroup.controls.anzahl_der_einrichtungen.value)
          FeWoh.anzahl_der_gaesteanreisen_pro_jahr=Number(this.FeWoFormGroup.controls.anzahl_der_gaesteanreisen_pro_jahr.value)
          FeWoh.anzahl_der_uebernachtungen_mai_bis_oktober=Number(this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_mai_bis_oktober.value)
          FeWoh.anzahl_der_uebernachtungen_november_bis_april=Number(this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_november_bis_april.value)
          FeWoh.durchschnittliche_groesse_der_fe_wo_in_m2=Number(this.FeWoFormGroup.controls.durchschnittliche_groesse_der_fe_wo_in_m2.value)
          FeWoh.durchschnittliche_maximale_belegungszahl_der_fe_wo=Number(this.FeWoFormGroup.controls.durchschnittliche_maximale_belegungszahl_der_fe_wo.value)
          this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo = FeWoh
          this.Dataservice.saveFeWo()
      }
      this.Dataservice.deleteHotel()

    }else{
      //nicht selected (also es ist ein Hotel)
      //lösche die FeWo, wenn das nicht selected ist: = null
      if(this.selected !='Ferienwohnungen'){
        var FeWoID=1
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.FeWo = null
        this.Dataservice.deleteFeWo(FeWoID)
      }
      
    }
    //Flaeche Enthalten Toggle: -> Berechnung:
    if(this._SlideToggle_flaecheEnthalten){
      if(this._SlideToggle_flaecheEnthalten.checked)
      {
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.privatWohnungInGesamtFlaecheEnthalten=true  
      }else{
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.privatWohnungInGesamtFlaecheEnthalten=false
      }
    }
    //Laufen die Verbraeuche eigenen Privatwohnung über dieselben Zaehler wie die der FeWo(s)?
    if(this._SlideToggle_privateVerbraeucheSelberZaehler){
      //Heizung
      if(this._SlideToggle_privatHeizung && this._SlideToggle_privatHeizung.checked)
      {
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_heizungszaehler=true  
      }else{
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_heizungszaehler=false
      }
      //Strom
      if(this._SlideToggle_privatStrom && this._SlideToggle_privatStrom.checked)
      {
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_stromzaehler=true  
      }else{
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_stromzaehler=false
      }
      //Wasser
      if(this._SlideToggle_privatWasser && this._SlideToggle_privatWasser.checked)
      {
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_wasserzaehler=true  
      }else{
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_wasserzaehler=false
      }
      //Restmüll
      if(this._SlideToggle_privatRestmuell && this._SlideToggle_privatRestmuell.checked)
      {
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_restmuell=true  
      }else{
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.kein_privater_restmuell=false
      }
    }    
    //jetzt Behavoir Object updaten: 
    this.Dataservice.UmweltchecksBhobj.next(this.Dataservice.Umweltchecks)

    //schicke alle http-Requests ab (weil hier erst alle Daten komplett sind: 
    this.Dataservice.saveEinrichtung()
        
  }
}
  
  pruefeEingabeDerBetriebsdatencomponente_Hotel(){
        /*
    this.betriebsdatenFormGroup = new FormGroup({
      anzahl_bettenuebernachtungen_pro_jahr  :  new FormControl('', [Validators.required,Validators.min(0),Validators.minLength(1)]),
      anzahl_der_einrichtungen  :  new FormControl('', [Validators.required]),
      beheizte_gesamtflaeche_in_m3  :  new FormControl('', [Validators.required]),
      davon_privatwohnflaeche_in_m2  :  new FormControl('', [Validators.required]), 
      eigenwaesche_in_kg  :  new FormControl('', [Validators.required]),
      anzahl_mahlzeiten_im_jahr  :  new FormControl('', [Validators.required]), 
      abfall_in_kg_pro_jahr  :  new FormControl('', [Validators.required]) ,
      checkedFormControl_sterne :  new FormControl('', [Validators.required]),
    })*/
    if(this.betriebsdatenFormGroup.controls.anzahl_der_einrichtungen.invalid)
      this.Pruefservice.pushNameErrorArray("Anzahl der Zimmer/Einrichtungen. Bitte geben Sie eine Zahl größer 0 an.",this.betriebsdatenFormGroup.controls.anzahl_der_einrichtungen.errors)

    if(this.betriebsdatenFormGroup.controls.anzahl_bettenuebernachtungen_pro_jahr.invalid)
      this.Pruefservice.pushNameErrorArray("Anzahl der Bettenübernachtungen pro Jahr. Bitte geben Sie eine Zahl größer 0 an.",this.betriebsdatenFormGroup.controls.anzahl_bettenuebernachtungen_pro_jahr.errors)
    if(this.betriebsdatenFormGroup.controls.beheizte_gesamtflaeche_in_m3.invalid)
      this.Pruefservice.pushNameErrorArray("Beheizte Gesamtfläche. Bitte geben Sie eine Zahl größer 0 an.",this.betriebsdatenFormGroup.controls.beheizte_gesamtflaeche_in_m3.errors)
    if(this.betriebsdatenFormGroup.controls.eigenwaesche_in_kg.invalid)
      this.Pruefservice.pushNameErrorArray("Eigenwäsche",this.betriebsdatenFormGroup.controls.eigenwaesche_in_kg.errors)
    if(this.betriebsdatenFormGroup.controls.anzahl_mahlzeiten_im_jahr.invalid)
      this.Pruefservice.pushNameErrorArray("Anzahl der ausgegebenen Mahlzeiten pro Jahr. Bitte geben Sie eine Zahl größer 0 an.",this.betriebsdatenFormGroup.controls.anzahl_mahlzeiten_im_jahr.errors)
    if(this.betriebsdatenFormGroup.controls.anteil_mehrgaenge_menues.invalid)
      this.Pruefservice.pushNameErrorArray("Anteil Mehrgaenge_menues. Bitte geben Sie eine Zahl größer 0 an.",this.betriebsdatenFormGroup.controls.anteil_mehrgaenge_menues.errors)
    if(this.betriebsdatenFormGroup.controls.tagungsraumflaeche.invalid)
      this.Pruefservice.pushNameErrorArray("Tagungsraumflaeche. Bitte geben Sie eine Zahl größer gleich 0 an.",this.betriebsdatenFormGroup.controls.tagungsraumflaeche.errors)

    if(this.betriebsdatenFormGroup.controls.anteil_mehrgaenge_menues.invalid)
      this.Pruefservice.pushNameErrorArray("Anteil Mehrgaenge_menues",this.betriebsdatenFormGroup.controls.anteil_mehrgaenge_menues.errors)


    if(Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.oekostromverbrauch_in_kwh)/(Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.stromverbrauch_in_kwh)+Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.oekostromverbrauch_in_kwh))*100<0||(Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.oekostromverbrauch_in_kwh))/(Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.stromverbrauch_in_kwh)+Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.oekostromverbrauch_in_kwh))*100 > 100)
    {
      this.Pruefservice.pushNameErrorArray("Ökostromverbrauch in %: muss zwischen 0 % und 100 % liegen",this.betriebsdatenFormGroup.controls.oekostromverbrauch_in_kwh.errors)
    }  

    if(this.betriebsdatenFormGroup.controls.checkedFormControl_sterne.invalid || this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_der_sterne==0)
      this.Pruefservice.pushNameErrorArray("Anzahl der Sterne",this.betriebsdatenFormGroup.controls.checkedFormControl_sterne.errors)

    this.Pruefservice.pushInvalidFormgroup("Betriebsdaten",this.betriebsdatenFormGroup)
 /*this.FeWoFormGroup = new FormGroup({ 
      anzahl_der_gaesteanreisen_pro_jahr:  new FormControl('', [Validators.required]),
      anzahl_der_uebernachtungen_mai_bis_oktober:  new FormControl('', [Validators.required]),
      anzahl_der_uebernachtungen_november_bis_april:  new FormControl('', [Validators.required]),
      durchschnittliche_groesse_der_fe_wo_in_m2:  new FormControl('', [Validators.required]),
      durchschnittliche_maximale_belegungszahl_der_fe_wo:  new FormControl('', [Validators.required]),
    })*/ 
    
    console.log("pruef Privat wohnen")
    if(this._SlideToggle_privat.checked){
      
      if(this.privatFormGroup.controls.anzahl_privater_bewohner.invalid)
         this.Pruefservice.pushNameErrorArray("Anzahl Bewohner",this.privatFormGroup.controls.anzahl_privater_bewohner.errors)
      if(this.privatFormGroup.controls.DavonPrivatwohnflaecheInM2.invalid)
         this.Pruefservice.pushNameErrorArray("Wohnfläche in m²",this.privatFormGroup.controls.DavonPrivatwohnflaecheInM2.errors)
      //if(this.privatFormGroup.controls.checkedFormControl3.invalid)
       //  this.Pruefservice.pushNameErrorArray("In Gesamtfläche enthalten",this.privatFormGroup.controls.checkedFormControl3.errors)
      //if(this.privatFormGroup.controls.checkedFormControl4_privateVerbraeuche.invalid)
       //  this.Pruefservice.pushNameErrorArray("Laufen private Verbräuche über den gleichen Zähler?",this.privatFormGroup.controls.checkedFormControl4_privateVerbraeuche.errors)
      //private Verbräuche
      /*if(this._SlideToggle_privateVerbraeucheSelberZaehler.checked){        
        if(this.privatFormGroup.controls.checkedFormControl5_privateVerbraeucheStrom.invalid)
          this.Pruefservice.pushNameErrorArray("Private Verbraeuche Strom",this.privatFormGroup.controls.checkedFormControl5_privateVerbraeucheStrom.errors)
        if(this.privatFormGroup.controls.checkedFormControl6_privateVerbraeucheHeizung.invalid)
          this.Pruefservice.pushNameErrorArray("Private Verbraeuche Heizung",this.privatFormGroup.controls.checkedFormControl6_privateVerbraeucheHeizung.errors)
        if(this.privatFormGroup.controls.checkedFormControl7_privateVerbraeucheWasser.invalid)
          this.Pruefservice.pushNameErrorArray("Private Verbräuche Wasser",this.privatFormGroup.controls.checkedFormControl7_privateVerbraeucheWasser.errors)
        }*/
        //Wenn es private Verbräuche gibt, muss einer gechecked sein:
      if(this._SlideToggle_privateVerbraeucheSelberZaehler.checked ){
        //dann muss einer der toggles angeschoben sein, sonst gibs Fehlermeldung
        if(!((this._SlideToggle_privatHeizung&&this._SlideToggle_privatHeizung.checked)||(this._SlideToggle_privatStrom&&this._SlideToggle_privatStrom.checked)||(this._SlideToggle_privatWasser&&this._SlideToggle_privatWasser.checked))){
           this.Pruefservice.pushNameErrorArray("Private Verbräuche: Bitte geben Sie an, ob Strom, Heizung über Wasser über denselben Zähler läuft.",this.privatFormGroup.controls.checkedFormControl7_privateVerbraeucheWasser.errors)
        }
      }
      this.Pruefservice.pushInvalidFormgroup("Privat-/Mitarbeiterwohnungen",this.privatFormGroup)
     }
    /*this.privatFormGroup = new FormGroup({
      checkedFormControl2 : new FormControl('', [Validators.required]),
      anzahl_privater_bewohner  :  new FormControl('', [Validators.required]),
      DavonPrivatwohnflaecheInM2 : new FormControl('', [Validators.required]),
      checkedFormControl3 : new FormControl('', [Validators.required]),
      checkedFormControl4_privateVerbraeuche :  new FormControl('', [Validators.required]),
      checkedFormControl5_privateVerbraeucheStrom :  new FormControl('', [Validators.required]),
      checkedFormControl6_privateVerbraeucheHeizung :  new FormControl('', [Validators.required]),
      checkedFormControl7_privateVerbraeucheWasser :  new FormControl('', [Validators.required]),

      checkedFormControl5_led :  new FormControl('', [Validators.required]),
    })*/
  }
  
  pruefeEingabeDerBetriebsdatencomponente_FeWo(){
      /*
  this.betriebsdatenFormGroup = new FormGroup({
    anzahl_bettenuebernachtungen_pro_jahr  :  new FormControl('', [Validators.required,Validators.min(0),Validators.minLength(1)]),
    anzahl_der_einrichtungen  :  new FormControl('', [Validators.required]),
    beheizte_gesamtflaeche_in_m3  :  new FormControl('', [Validators.required]),
    davon_privatwohnflaeche_in_m2  :  new FormControl('', [Validators.required]), 
    eigenwaesche_in_kg  :  new FormControl('', [Validators.required]),
    anzahl_mahlzeiten_im_jahr  :  new FormControl('', [Validators.required]), 
    abfall_in_kg_pro_jahr  :  new FormControl('', [Validators.required]) ,
    checkedFormControl_sterne :  new FormControl('', [Validators.required]),
  })*/
  if(this.betriebsdatenFormGroup.controls.anzahl_der_einrichtungen.invalid)
    this.Pruefservice.pushNameErrorArray("Anzahl der Zimmer/Einrichtungen. Bitte geben Sie eine Zahl größer 0 an.",this.betriebsdatenFormGroup.controls.anzahl_der_einrichtungen.errors)

  if(this.betriebsdatenFormGroup.controls.anzahl_bettenuebernachtungen_pro_jahr.invalid)
    this.Pruefservice.pushNameErrorArray("Anzahl der Bettenübernachtungen pro Jahr. Bitte geben Sie eine Zahl größer 0 an.",this.betriebsdatenFormGroup.controls.anzahl_bettenuebernachtungen_pro_jahr.errors)
  if(this.betriebsdatenFormGroup.controls.beheizte_gesamtflaeche_in_m3.invalid)
    this.Pruefservice.pushNameErrorArray("Beheizte Gesamtfläche. Bitte geben Sie eine Zahl größer 0 an.",this.betriebsdatenFormGroup.controls.beheizte_gesamtflaeche_in_m3.errors)
  if(this.betriebsdatenFormGroup.controls.eigenwaesche_in_kg.invalid)
    this.Pruefservice.pushNameErrorArray("Eigenwäsche",this.betriebsdatenFormGroup.controls.eigenwaesche_in_kg.errors)
  if(Number(this.betriebsdatenFormGroup.controls.anzahl_mahlzeiten_im_jahr.value)<0)
     this.Pruefservice.pushNameErrorArray("Anzahl der ausgegebenen Mahlzeiten pro Jahr. Bitte geben Sie eine Zahl größer gleich 0 an.",this.betriebsdatenFormGroup.controls.anzahl_mahlzeiten_im_jahr.errors)
  //if(Number(this.betriebsdatenFormGroup.controls.anteil_mehrgaenge_menues.value)<0)
  // this.Pruefservice.pushNameErrorArray("Anteil Mehrgaenge_menues. Bitte geben Sie eine Zahl größer gleich 0 an.",this.betriebsdatenFormGroup.controls.anteil_mehrgaenge_menues.errors)
 // if(Number(this.betriebsdatenFormGroup.controls.anteil_mehrgaenge_menues.value)<0)
  //  this.Pruefservice.pushNameErrorArray("Anteil Mehrgaenge_menues",this.betriebsdatenFormGroup.controls.anteil_mehrgaenge_menues.errors)


  if(Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.oekostromverbrauch_in_kwh)/(Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.stromverbrauch_in_kwh)+Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.oekostromverbrauch_in_kwh))*100<0||(Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.oekostromverbrauch_in_kwh))/(Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.stromverbrauch_in_kwh)+Number(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.oekostromverbrauch_in_kwh))*100 > 100)
  {
    this.Pruefservice.pushNameErrorArray("Ökostromverbrauch in %: muss zwischen 0 % und 100 % liegen",this.betriebsdatenFormGroup.controls.oekostromverbrauch_in_kwh.errors)
  }  

  if(this.betriebsdatenFormGroup.controls.checkedFormControl_sterne.invalid || this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.anzahl_der_sterne==0)
    this.Pruefservice.pushNameErrorArray("Anzahl der Sterne",this.betriebsdatenFormGroup.controls.checkedFormControl_sterne.errors)

  this.Pruefservice.pushInvalidFormgroup("Betriebsdaten",this.betriebsdatenFormGroup)

  //Ferienwohungsprüfung:
    if(this.FeWoFormGroup.controls.anzahl_der_gaesteanreisen_pro_jahr&&(this.FeWoFormGroup.controls.anzahl_der_gaesteanreisen_pro_jahr.invalid||Number(this.FeWoFormGroup.controls.anzahl_der_gaesteanreisen_pro_jahr.value)==0))
      this.Pruefservice.pushNameErrorArray("Anzahl der Gästeanreisen",this.FeWoFormGroup.controls.anzahl_der_gaesteanreisen_pro_jahr.errors)
    if(this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_mai_bis_oktober.invalid||Number(this.FeWoFormGroup.controls.value)==0)
      this.Pruefservice.pushNameErrorArray("Anzahl der Übernachtungen Mai bis Oktober",this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_mai_bis_oktober.errors)
    if(this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_november_bis_april.invalid||Number(this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_mai_bis_oktober.value)==0)
      this.Pruefservice.pushNameErrorArray("Anzahl der Übernachtungen November bis April",this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_november_bis_april.errors)
    if(this.FeWoFormGroup.controls.durchschnittliche_groesse_der_fe_wo_in_m2.invalid||Number(this.FeWoFormGroup.controls.anzahl_der_uebernachtungen_november_bis_april.value)==0)
      this.Pruefservice.pushNameErrorArray("Durschnittliche Größe der Ferienwohnung in m²",this.FeWoFormGroup.controls.durchschnittliche_groesse_der_fe_wo_in_m2.errors)
    if(this.FeWoFormGroup.controls.durchschnittliche_maximale_belegungszahl_der_fe_wo.invalid||Number(this.FeWoFormGroup.controls.durchschnittliche_groesse_der_fe_wo_in_m2.value)==0)
      this.Pruefservice.pushNameErrorArray("Durchschnittliche maximale Belegungszahl",this.FeWoFormGroup.controls.durchschnittliche_maximale_belegungszahl_der_fe_wo.errors) 
    this.Pruefservice.pushInvalidFormgroup("Ferienwohnungen",this.FeWoFormGroup)

/*this.FeWoFormGroup = new FormGroup({ 
  anzahl_der_gaesteanreisen_pro_jahr:  new FormControl('', [Validators.required]),
  anzahl_der_uebernachtungen_mai_bis_oktober:  new FormControl('', [Validators.required]),
  anzahl_der_uebernachtungen_november_bis_april:  new FormControl('', [Validators.required]),
  durchschnittliche_groesse_der_fe_wo_in_m2:  new FormControl('', [Validators.required]),
  durchschnittliche_maximale_belegungszahl_der_fe_wo:  new FormControl('', [Validators.required]),
})*/ 

console.log("pruef Privat wohnen")
if(this._SlideToggle_privat.checked){
  
  if(this.privatFormGroup.controls.anzahl_privater_bewohner.invalid)
     this.Pruefservice.pushNameErrorArray("Anzahl Bewohner",this.privatFormGroup.controls.anzahl_privater_bewohner.errors)
  if(this.privatFormGroup.controls.DavonPrivatwohnflaecheInM2.invalid)
     this.Pruefservice.pushNameErrorArray("Wohnfläche in m²",this.privatFormGroup.controls.DavonPrivatwohnflaecheInM2.errors)
  //if(this.privatFormGroup.controls.checkedFormControl3.invalid)
   //  this.Pruefservice.pushNameErrorArray("In Gesamtfläche enthalten",this.privatFormGroup.controls.checkedFormControl3.errors)
  //if(this.privatFormGroup.controls.checkedFormControl4_privateVerbraeuche.invalid)
   //  this.Pruefservice.pushNameErrorArray("Laufen private Verbräuche über den gleichen Zähler?",this.privatFormGroup.controls.checkedFormControl4_privateVerbraeuche.errors)
  //private Verbräuche
  /*if(this._SlideToggle_privateVerbraeucheSelberZaehler.checked){        
    if(this.privatFormGroup.controls.checkedFormControl5_privateVerbraeucheStrom.invalid)
      this.Pruefservice.pushNameErrorArray("Private Verbraeuche Strom",this.privatFormGroup.controls.checkedFormControl5_privateVerbraeucheStrom.errors)
    if(this.privatFormGroup.controls.checkedFormControl6_privateVerbraeucheHeizung.invalid)
      this.Pruefservice.pushNameErrorArray("Private Verbraeuche Heizung",this.privatFormGroup.controls.checkedFormControl6_privateVerbraeucheHeizung.errors)
    if(this.privatFormGroup.controls.checkedFormControl7_privateVerbraeucheWasser.invalid)
      this.Pruefservice.pushNameErrorArray("Private Verbräuche Wasser",this.privatFormGroup.controls.checkedFormControl7_privateVerbraeucheWasser.errors)
    }*/
    //Wenn es private Verbräuche gibt, muss einer gechecked sein:
  if(this._SlideToggle_privateVerbraeucheSelberZaehler.checked ){
    //dann muss einer der toggles angeschoben sein, sonst gibs Fehlermeldung
    if(!((this._SlideToggle_privatHeizung&&this._SlideToggle_privatHeizung.checked)||(this._SlideToggle_privatStrom&&this._SlideToggle_privatStrom.checked)||(this._SlideToggle_privatWasser&&this._SlideToggle_privatWasser.checked))){
       this.Pruefservice.pushNameErrorArray("Private Verbräuche: Bitte geben Sie an, ob Strom, Heizung über Wasser über denselben Zähler läuft.",this.privatFormGroup.controls.checkedFormControl7_privateVerbraeucheWasser.errors)
    }
  }
  this.Pruefservice.pushInvalidFormgroup("Privat-/Mitarbeiterwohnungen",this.privatFormGroup)
 }
/*this.privatFormGroup = new FormGroup({
  checkedFormControl2 : new FormControl('', [Validators.required]),
  anzahl_privater_bewohner  :  new FormControl('', [Validators.required]),
  DavonPrivatwohnflaecheInM2 : new FormControl('', [Validators.required]),
  checkedFormControl3 : new FormControl('', [Validators.required]),
  checkedFormControl4_privateVerbraeuche :  new FormControl('', [Validators.required]),
  checkedFormControl5_privateVerbraeucheStrom :  new FormControl('', [Validators.required]),
  checkedFormControl6_privateVerbraeucheHeizung :  new FormControl('', [Validators.required]),
  checkedFormControl7_privateVerbraeucheWasser :  new FormControl('', [Validators.required]),

  checkedFormControl5_led :  new FormControl('', [Validators.required]),
})*/
}
}