import {nullString, nullInt} from "../_models/index"

export class Kunde {
    kunden_id              : number;   
    name_des_betriebes     : string;
    kunden_nummer          : string;
    ansprechpartner        : string;
    ansprechpartner_email  : string;
    ansprechpartner_telefon: string;
    ansprechpartner_mobil  : string;
    ansprechpartner_ansprache : string; 
    username               : string;
    notiz                  : string;
    deaktiviert            : boolean;
    zustimmung_newsletter  : boolean;
    zustimmung_ds          : boolean;
    zustimmung_newsletter_datum :Date;
    zustimmung_ds_datum : Date;

    firmen_adresse_id      : number;
    rechnungs_adresse_id   : number; 

    passwort_hash : string ;

    constructor() {
        this.kunden_id = 0;
        this.name_des_betriebes = '';
        this.kunden_nummer = "";
        this.ansprechpartner  = '';
        this.ansprechpartner_email  = '';
        this.ansprechpartner_telefon = "";
        this.ansprechpartner_mobil  = "";
        this.notiz = "";
        this.firmen_adresse_id = 0;
        this.rechnungs_adresse_id = 0;
    }
}

