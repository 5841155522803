import { Component, OnInit, Injectable, Output, ViewChild } from '@angular/core';
import {MatStepperModule, MatStepper} from '@angular/material/stepper';
import { FormBuilder, FormGroup, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import {DataService} from '../_services/data.service'
import { PruefService } from '../_services/pruefservice.service';
import {EventEmitter} from '@angular/core';

@Component({
  selector: 'app-eingabe',
  templateUrl: './eingabe.component.html',
  styleUrls: ['./eingabe.component.css']
})
@Injectable({
  providedIn: 'root',
})
export class EingabeComponent implements OnInit {
  //wenn man auf next klickt, haut die Eingabekomponente die Events raus:
  @Output() saveIntroDaten = new EventEmitter<void>();
  @Output() saveKunde = new EventEmitter<void>();
  @Output() saveAdressen = new EventEmitter<void>();
  @Output() saveBetriebsdaten = new EventEmitter<void>();
  @Output() saveStromHeizung = new EventEmitter<void>();
  @Output() saveWasser = new EventEmitter<void>();
  @Output() saveStrom = new EventEmitter<void>();
  @Output() saveRestabfalltonnen = new EventEmitter<void>();
  @Output() abgabeQuickcheckUmwelt = new EventEmitter<void>();
  @Output() pruefeEingabe = new EventEmitter<void>();
  
  @ViewChild('stepper', { static: true }) stepper: MatStepper;

  zeroFormGroup : FormGroup;
  firstFormGroup : FormGroup;
  secondFormGroup : FormGroup;
  thirdFormGroup : FormGroup;
  fourthFormGroup : FormGroup; 
  fifthFormGroup : FormGroup; 
  sixthFormGroup : FormGroup; 
  seventhFormGroup : FormGroup; 
  abgabeFormGroup : FormGroup;
  
  disabled : boolean;

  constructor(private _formBuilder: FormBuilder, private Dataservice : DataService,private Pruefservice : PruefService) {
    
    this.zeroFormGroup = new FormGroup({})
    this.firstFormGroup = new FormGroup({})
    this.secondFormGroup = new FormGroup({})
    this.thirdFormGroup = new FormGroup({})
    this.fourthFormGroup = new FormGroup({})
    this.fifthFormGroup = new FormGroup({})
    this.sixthFormGroup = new FormGroup({})
    this.seventhFormGroup = new FormGroup({})
    this.abgabeFormGroup = new FormGroup({})    
    this.disabled=true;

  }
  saveEverything(){
    console.log("SaveEverything: StepperIndex:",this.stepper.selectedIndex)    
    
    console.log("pruefung: ResetPruefService")
    this.Pruefservice.ResetPruefungService()
    this.pruefeEingabe.emit()

    switch(this.stepper.selectedIndex) {
      //Intro
      case 0:{ 
        console.log("Eingabe: emit saveIntroDaten")
        this.saveIntroDaten.emit()
        break; 
      }      
      //Kontaktdaten: Rechnungsadresse & Co 
      case 1: { 
        console.log("Eingabe: emit saveKunde & saveAdressen; case 1")
        this.saveKunde.emit() 
        this.saveAdressen.emit()
        break; 
      } 
      //Betriebsdaten
      case 2: { 
        console.log("Eingabe: emit saveBetriebsdaten->Einrichtung usw.")
        this.saveBetriebsdaten.emit()
         break; 
      }
      //Wasser
      case 3 : { 
        console.log("Eingabe: emit saveWasser")
        this.saveWasser.emit()   
        break;  
      } 
      //Strom
      case 4 : { 
        console.log("Eingabe: emit saveStrom")
        this.saveStrom.emit()   
        break; 
      }
      //Heizung und Anlagen (strom-Heizung)
      case 5 : { 
        console.log("Eingabe: emit saveStromHeizung")
        this.saveStromHeizung.emit()   
        break; 
      }
      //Restabfall
      case 6 : { 
        console.log("Eingabe: emit saveRestabfalltonnen")
        this.saveRestabfalltonnen.emit()
        break; 
      }
      //Pruefseite  
      case 7 : { 

        break; 
      }   
    }    
  } 
  ngOnInit(){
    this.firstFormGroup = this._formBuilder.group({

    });
    this.secondFormGroup = this._formBuilder.group({
    
    });
    setTimeout(()=>{ this.disabled=false; },4000)
  }
}