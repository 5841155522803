import { Component, OnInit, Input, EventEmitter,ViewChild  } from '@angular/core';
import { Anlage, Heizmittelnamen,Heizmittelvalue, BHKWvalues } from '../_models/index';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSelect} from '@angular/material/select';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import { DataService } from '../_services/data.service'
import { Subscription } from 'rxjs';
import { PruefService } from '../_services/pruefservice.service';

@Component({
  selector: 'app-strom-heizung',
  templateUrl: './strom-heizung.component.html',
  styleUrls: ['./strom-heizung.component.css']
})
export class StromHeizungComponent implements OnInit {
  //Eventlistener zum Speichern: die Komponente hört drauf
  @Input() saveStromHeizung : EventEmitter<void>;
  @Input() pruefeEingabe : EventEmitter<void>;

  @ViewChild('bhkw', { static: true }) _SlideToggle_bhkw: MatSlideToggle;
  @ViewChild('photo', { static: true }) _SlideToggle_photo: MatSlideToggle;
  @ViewChild('selectDropdownHeizung', { static: false }) _selectDropdown: MatSelect;
  
  heizmittelFormArray : FormArray;
  BHKWFormGroup : FormGroup;
  BHKWAnlagenFormGroup : FormGroup;
  BHKWEinzelFormGroup : FormGroup;
  PhotovoltaikFormGroup :FormGroup;
  heizmittelFormGroup:FormGroup;
  HeizmittelSelector : FormControl;
  BHKWtoggleFormControl : FormControl;
  
  BHKWFormArray : FormArray;
  BHKWSelector : FormControl;
  BHKWNamenArray : string[];

  matcher : ErrorStateMatcher;
  anlagen : Anlage[];
  Heizungen : Anlage[];
  Heizmittelnamen : Heizmittelnamen;
  Heizmittelvalues: Heizmittelvalue;
  HeizungsNamenArray: string[];
  HeizartSelectError :boolean;

  BHKWBetriebsmittelValues : BHKWvalues;
  BHKWBetriebsmittelSelectError :boolean;

  BHKWEingespeißtControl: FormControl;
  BHWKEigenverbrauchControl: FormControl;
  

  BHKWToggleGesperrt : boolean;

  heizungsCounter:number;

  BHKWs : Anlage[];

  pullcounter : number 

  //BHKW:
  BHKWRohstoff : string;
  umweltcheckSubscription : Subscription;

  constructor(private Dataservice : DataService, private Pruefservice : PruefService) {
    //HEIZUNG:
    this.Heizungen=[]
    this.Heizmittelnamen = new Heizmittelnamen()
    this.HeizmittelSelector = new FormControl('',[])
    this.HeizungsNamenArray=[]
    this.HeizartSelectError=false
    this.heizmittelFormArray=new FormArray([])
    this.heizmittelFormGroup = new FormGroup({
      heizmittelFormArray: new FormArray([
        //new FormControl('',[Validators.required]),
      ]),
    });
    //BHKW:
    this.BHKWBetriebsmittelValues = new BHKWvalues()
    this.BHKWSelector = new FormControl('',[])
    this.BHKWBetriebsmittelSelectError=false
    this.BHKWFormArray=new FormArray([])
    this.BHKWs =[]
    this.BHKWNamenArray=[]
    this.BHKWRohstoff="notSet"    
    this.BHKWAnlagenFormGroup = new FormGroup({
      BHKWFormArray : new FormArray([])
    })
    console.log("BHKW-FormGroup:",this.BHKWAnlagenFormGroup)
    this.BHKWFormGroup = new FormGroup({
      BHKWtoggleFormControl : new FormControl('',[]),
    })
    this.BHKWtoggleFormControl = new FormControl('',[]),
    this.BHKWEingespeißtControl = new FormControl('',[])
    this.BHWKEigenverbrauchControl = new FormControl('',[])

    //Photovoltaik:
    this.PhotovoltaikFormGroup  = new FormGroup({
        PhotovoltaikToggleFormControl : new FormControl (''),
        PhotovoltaikEigenverbrauchFormControl : new FormControl('',[]),
        PhotovoltaikEingespeistFormControl : new FormControl('',[]),
    })
    this.matcher = new ErrorStateMatcher();
    this.pullcounter=0
   }

  ngOnInit() {

    console.log("HeizmittelFormGroup:",this.heizmittelFormGroup)
    
    this.heizungsCounter=0
    this.Dataservice.UmweltchecksBhobj.subscribe(
      //Problem: wird einmal aufgerufen beim Konstruktor und einmal, wenn die Datengeupdated werden.
      (Umweltchecks) => {console.log("BETRIEBSDATENCOMPONENTE:",Umweltchecks); 
      if(Umweltchecks.Umweltchecks){
        this.pullcounter++
        if(this.pullcounter==1){
          this.pullDataInStromHeizungComponente() 
        }
      }
    },
      (error) => {console.log("ERROR in Betriebsdatencomponente.", error)}
    )
    //Listen to saveRestabfalltonnen-Event vom Parentcomponent: eingabe.component => SAVE local Data!
    this.saveStromHeizung.subscribe(
      ()=>{
        console.log("Anlagen: saveAnlagen")
        //lokal: schreibe die Werte in die Tonnen, dann in den Dataservice -
        this.saveAnlagen()},
      err=>{console.log(err)},
      complete=>{console.log("Anlagen erfolgereich vom Frontent versendet")}
    )
    this.pruefeEingabe.subscribe(()=>{
      console.log("pruefe StromHeizung")
      this.pruefeEingabeStromHeizungKomponente()
    })
  this.Heizmittelvalues=new Heizmittelvalue()
  console.log("Heizmittelvalues",this.Heizmittelvalues.Heizmittelvalues)
  }
  addHeizung(){
    console.log("ADDHEIZUNG",this.HeizmittelSelector.value)
    
    if((this.Heizungen.length<=20) && (this.HeizmittelSelector.value!=null)){
      this.heizungsCounter++
      this.HeizartSelectError=false
      var NewHeizung= new Anlage()
      NewHeizung.typ=this.HeizmittelSelector.value
      NewHeizung.name=NewHeizung.typ.replace("ae","ä")
      NewHeizung.name=NewHeizung.typ.replace("oe","ö")
      NewHeizung.name=NewHeizung.typ.replace("ue","ü")
      if(NewHeizung.typ.includes("kWh"))
      {
          //console.log("HEIZ Anlagentyp:",anlage.typ,"Heizmittelvalues:",Heizungsvalues.Heizmittelvalues[i],"Heizmittelname:",HeizungsNamen.Heizmittelnamen[i])
          NewHeizung.einheit="kWh"
      }
      if(NewHeizung.typ.includes("Kg")||NewHeizung.typ.includes("kg"))
      {
          //console.log("HEIZ Anlagentyp:",anlage.typ,"Heizmittelvalues:",Heizungsvalues.Heizmittelvalues[i],"Heizmittelname:",HeizungsNamen.Heizmittelnamen[i])
          NewHeizung.einheit="kg"
      }
      if(NewHeizung.typ.includes("Liter"))
      {
          //console.log("HEIZ Anlagentyp:",anlage.typ,"Heizmittelvalues:",Heizungsvalues.Heizmittelvalues[i],"Heizmittelname:",HeizungsNamen.Heizmittelnamen[i])
          NewHeizung.einheit="Liter"
      }
      if(NewHeizung.typ.includes("in t"))
      {
          //console.log("HEIZ Anlagentyp:",anlage.typ,"Heizmittelvalues:",Heizungsvalues.Heizmittelvalues[i],"Heizmittelname:",HeizungsNamen.Heizmittelnamen[i])
          NewHeizung.einheit="t"
      }
      this.Heizungen.push(NewHeizung)
      this.HeizungsNamenArray.push(String(NewHeizung.name))
      this.heizmittelFormArray.controls.push(new FormControl('',[Validators.required,Validators.min(1)]))
      this.heizmittelFormGroup.controls.heizmittelFormArray=this.heizmittelFormArray
      console.log("HeizmittelControls",this.heizmittelFormGroup.controls.heizmittelFormArray)
      console.log("Heizungen:", this.Heizungen)
    }else{
        this.HeizartSelectError=true
    }
    this.HeizmittelSelector.reset()
  }
  removeHeizung(){
    if(this.Heizungen.length>0){     
      this.HeizungsNamenArray.pop()

      this.heizmittelFormArray.removeAt(this.heizmittelFormArray.length-1); 
      this.heizmittelFormGroup.controls.heizmittelFormArray=this.heizmittelFormArray
      console.log("Heizung löschen:",this.heizmittelFormArray.length)
      this.Heizungen.pop()
    }  
  }  
  addBHKW(){
    console.log("BHKW-Selektorvalue:",this.BHKWSelector.value)
    
    if((this.BHKWs.length<=20) && ((this.BHKWSelector.value!=null)&&(this.BHKWSelector.value!=""))){
      this.BHKWNamenArray.push(this.BHKWSelector.value)
      this.BHKWNamenArray.push(this.BHKWSelector.value)
      //zwei Reinpuschen: 1. Eigenverbrauchter Strom; 2. Eingespeister Strom:
      this.BHKWFormArray.push(new FormControl('',[Validators.required,Validators.min(0)]))
      this.BHKWFormArray.push(new FormControl('',[Validators.required]))
      this.BHKWAnlagenFormGroup.controls.BHKWFormArray=this.BHKWFormArray

      var NewBHKW = new Anlage()
      NewBHKW.name=this.BHKWSelector.value   
      NewBHKW.typ=this.BHKWSelector.value

      this.BHKWs.push(NewBHKW)
      
      this.BHKWFormGroup.controls.BHKWFormArray=this.BHKWFormArray
      console.log("BHKW-addBHKW():", this.BHKWs)
    }
    this.BHKWSelector.reset()
  }
  removeBHKW(){
    this.BHKWFormArray.removeAt(this.BHKWFormArray.length-1);
    this.BHKWFormArray.removeAt(this.BHKWFormArray.length-1);

    this.BHKWFormGroup.controls.BHKWFormArray=this.BHKWFormArray
    this.BHKWs.pop()
    
    if(this.BHKWFormArray.length<1){
      this._SlideToggle_bhkw.disabled=false      
    }
  }
  testForDisableBHKWToggle(){
    if(this.BHKWFormArray.length>0){
       this._SlideToggle_bhkw.disabled=true
       this.BHKWToggleGesperrt = true
    }else{
      this._SlideToggle_bhkw.disabled=false
      this.BHKWToggleGesperrt = false
    }
  }
  pullDataInStromHeizungComponente(){
    if(this.Dataservice.Umweltchecks.Umweltchecks.length>0){
      
      //HEIZUNGEN alle Werte reinschmeißen aus dem Dataservice:
      this.Heizungen = this.Dataservice.getAnlagenAusAnlangenArray('Heizung')
      var waermepumpe= this.Dataservice.getAnlagenAusAnlangenArray('Waermepumpe')
      if(waermepumpe[0]!=undefined){
        this.Heizungen.push(waermepumpe[0])
      }
      
      console.log("Heizungen anzuzeigende:", this.Heizungen)      
      //2. pushe das heizmittelFormGroup.controls.heizmittelFormArray.controls mit values
        for (let Heizung of this.Heizungen){
          if(Heizung!=undefined){
            this.heizmittelFormArray.push(new FormControl(Heizung.rohstoff_verbrauch,[Validators.required]))
            this.heizmittelFormGroup.controls.heizmittelFormArray=this.heizmittelFormArray
            this.HeizungsNamenArray.push(Heizung.name)
          }
        }
        console.log("HeizmittelFormArray : ",this.heizmittelFormArray)

      //BHKW get alle Werte:
      this.BHKWs = this.Dataservice.getAnlagenAusAnlangenArray('BHKW')
      console.log("BHKWs: ", this.BHKWs)
 
      //iteriert durch alle BHKW und toggelt, wenn es Werte gibt
      //und setzt den Wert vom letzten
      var i=0;
      var toggled=false
      if(this.BHKWs.length>0){
        for (let BHKW of this.BHKWs) {
          if(((BHKW.strom_eigenverbrauch>0) || (BHKW.strom_netzeinspeisung) > 0)&&toggled==false){
            
            //weil das NG-IF den Knopf nicht vorher lädt:
            setTimeout(()=>{this._SlideToggle_bhkw.toggle()},500)
              
            toggled=true
          }
          if(((BHKW.strom_eigenverbrauch>0) || (BHKW.strom_netzeinspeisung) > 0)){
            //Werte setzen nach 1 Sek:c
            //eine Sekunde warten, weil ng-if das erst in die DOM laden muss #AngularFail
            //Erstelle neues BHKW:
            setTimeout((BHKW : Anlage) => {
            console.log("BHKW LADEN:",i,": ", BHKW)
            this.BHKWNamenArray.push(BHKW.name)            
            this.BHKWNamenArray.push(BHKW.name)            
            
            this.BHKWFormArray.push(new FormControl(BHKW.strom_eigenverbrauch,[Validators.required]))
            this.BHKWFormArray.push(new FormControl(BHKW.strom_netzeinspeisung,[]))
            this.BHKWAnlagenFormGroup.controls.BHKWFormArray=this.BHKWFormArray
            //console.log("BHKWs - Set: :",this.BHKWAnlagenFormGroup.controls.BHKWFormArray)
            }, 1000, BHKW);              
          }
          i++;
        }
      }
      //Photovoltaik-Toggle:
      var Photovoltaik = this.Dataservice.getAnlagenAusAnlangenArray('Photovoltaik')
     
      console.log("PHOTOVOLTAIK#####: ", Photovoltaik) 
      //iteriert durch alle BHKW und toggelt, wenn es Werte gibt
        if( (Photovoltaik.length>0) && (Photovoltaik[0].strom_netzeinspeisung || Photovoltaik[0].strom_eigenverbrauch > 0)){
          this._SlideToggle_photo.toggle()  
          //Werte setzen nach 1 Sek:c
          //eine Sekunde warten, weil ng-if das erst in die DOM laden muss #AngularFail
          setTimeout((Photovoltaik : Anlage) => {  
           this.PhotovoltaikFormGroup.controls.PhotovoltaikEigenverbrauchFormControl.setValue(Photovoltaik.strom_eigenverbrauch)
           this.PhotovoltaikFormGroup.controls.PhotovoltaikEingespeistFormControl.setValue(Photovoltaik.strom_netzeinspeisung)
        }, 500, Photovoltaik[0]);          
        }
      //Photovoltaik-toggle
    }
  }
  //schreibt die Werte aus den Eingabeforms in die Anlageobjekte und speichert die ab:
  saveAnlagen(){    
    //alle Anlagen löschen
    this.Dataservice.deleteAnlagen()
    var anlagen : Anlage[]=[]; 
    //alle Heizungen in Dataservice puschen:
    this.Dataservice.addAnlagen(this.Heizungen)
    //Heizung: einmal alle Heizungen im Heizungsarray durchgehen und die Werte zuweisen:j
    var i = 0
    for(let HeizmittelControl of this.heizmittelFormArray.controls){
      //Problem: wir haben mehr Heizungscontrols als Heizungen
      //To Do: Warum eigentlich????
      if(this.Heizungen[i]){      
        console.log("Save Anlagen: HeitmittelControl.value: ",HeizmittelControl.value)
        console.log("zugehörige Heizung: ", this.Heizungen[i])
        this.Heizungen[i].rohstoff_verbrauch=Number(HeizmittelControl.value)
        this.Heizungen[i].strom_eigenverbrauch=0
        this.Heizungen[i].strom_netzeinspeisung=0
      }
      i++
    }
    anlagen = anlagen.concat(this.Heizungen)

    //BHKW: 
    //wenn toggle gechecked, speicher die Anlagen:
    if(this._SlideToggle_bhkw.checked){
      i=0
      var BHKWindex = 0 
      //einmal alle BHKW durchgehen und value saven
      for(let BHKWControl of this.BHKWFormArray.controls){
          //Problem: hier sind 2 Formcontrols für 1 BHKW zuständig
          //also immer durch 2 rechnen:
          //bei 0: 0 % 0 = 0 
          if(i %2 == 0){
            BHKWindex = i/2
          this.BHKWs[BHKWindex].strom_eigenverbrauch=Number(BHKWControl.value)
          }
          if(i %2 == 1){
            this.BHKWs[BHKWindex].strom_netzeinspeisung=Number(BHKWControl.value)
          }
          //nullvalues setzten:
          this.BHKWs[BHKWindex].rohstoff_verbrauch=0        
        i++
      }      
    }
    anlagen = anlagen.concat(this.BHKWs)
    //Photovoltaikanlage
    //toggle gechecked:
    if(this._SlideToggle_photo.checked){
      var anlage = new Anlage()
      anlage.typ="Photovoltaik"
      anlage.strom_netzeinspeisung=Number(this.PhotovoltaikFormGroup.controls.PhotovoltaikEingespeistFormControl.value)     
      anlage.strom_eigenverbrauch=Number(this.PhotovoltaikFormGroup.controls.PhotovoltaikEigenverbrauchFormControl.value)
      anlage.rohstoff_verbrauch=0
      anlagen.push(anlage) 
    }else{
      //muss er die Anlage löschen, falls Sie da ist: 

    }
    console.log("SAVE ANLAGEN Strom-Heizung-Componente:", anlagen)
    //Daten im Dataservice speichern: 
    this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Anlagen=anlagen
    this.Dataservice.UmweltchecksBhobj.next(this.Dataservice.Umweltchecks)

    //Dataservice wegschicken an Backend: 
    this.Dataservice.saveAnlagen()
  }
  pruefeEingabeStromHeizungKomponente(){
    //jede Heizung hat nur ein Feld
    var i :number = 0 
    for (let control of this.heizmittelFormArray.controls){
      if(control.invalid && !(this.Heizungen[i].name=="Waermepumpe")){
         this.Pruefservice.pushNameErrorArray(this.Heizungen[i].name + " muss größer als 0 sein.",control.errors)
      }
      i++
    }
    if (this.heizmittelFormArray.controls.length==0){ //es gibt keine Heizungen
      this.Pruefservice.pushNameErrorArray("Bitte fügen Sie eine Heizung hinzu",null)
    }

    this.Pruefservice.pushInvalidFormgroup("Heizungen",this.heizmittelFormGroup)
    
    var BHWKcontrol1hasValue : boolean = false
    var BHKWcontrol2hasValue : boolean = false
    
    if(this._SlideToggle_bhkw.checked){
      //BHKW hat zwei Felder pro BHKW
      //Problem: Der Kunde muss nur in eine von beiden was eintragen
      //Wenn in beiden nix steht, muss es einen Fehler geben:
      i=1
      for (let control2 of this.BHKWFormArray.controls){
        if(i%2==1) { //startet mit i=1 || Eigenverbrauch
          if(Number(control2.value)>0 ){
            BHWKcontrol1hasValue=true
          }
          //this.Pruefservice.pushNameErrorArray("BHKW "+ String(i-1) +": Eigenverbrauch",control2.errors)
        }
        if(i%2==0){ //eingespeißt:
          //this.Pruefservice.pushNameErrorArray("BHKW "+ String(i-2) +": eingespeißt",control2.errors)
          if(Number(control2.value)>0 ){
            BHKWcontrol2hasValue=true
          }
          //beide Prüfen: gibt Error, wenn beide false sind:
          if( !BHWKcontrol1hasValue && !BHKWcontrol2hasValue ){
            this.Pruefservice.pushNameErrorArray("BHKW "+ String(i-2) +" eingespeißt oder Eigenverbrauch muss größer als 0 sein ",control2.errors)
          }
          //beide Variablen zurücksetzen:
          BHWKcontrol1hasValue = false
          BHKWcontrol2hasValue = false
        }
        i++
      }
      this.Pruefservice.pushInvalidFormgroup("BHKW",this.BHKWFormGroup)

    }
    if(this._SlideToggle_photo.checked){
                //beide Prüfen: gibt Error, wenn beide unter 0 sind:
                if( Number(this.PhotovoltaikFormGroup.controls.PhotovoltaikEigenverbrauchFormControl.value)<=0 && Number(this.PhotovoltaikFormGroup.controls.PhotovoltaikEingespeistFormControl.value<=0)) {
                  this.Pruefservice.pushNameErrorArray("Photovoltaik eingespeißt oder Eigenverbrauch muss größer als 0 sein", this.PhotovoltaikFormGroup.controls.PhotovoltaikEigenverbrauchFormControl.errors)
                }
      this.Pruefservice.pushInvalidFormgroup("Photovoltaikanlage",this.PhotovoltaikFormGroup)
    }
  }   
}
