import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../_models/index';
import { Kunde } from '../_models/index';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

    create(user: User) {
        let kunde = new Kunde()
        kunde.ansprechpartner = user.firstName + " " + user.lastName;
        kunde.username  = user.username;
        kunde.passwort_hash          = user.password;
        kunde.zustimmung_newsletter=user.zustimmung_newsletter
        kunde.zustimmung_ds=user.zustimmung_ds
        

        console.log("Regiser User: ", kunde)
        return this.http.post('/go/register/', kunde);
    }

    orderNewPassword(user: User){
        return this.http.post('/go/orderNewPassword/', user);
    }
}