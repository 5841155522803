export class begleitfrage {
    begleitfrage_id: number;
    einrichtungs_id: number;
    begleitfrage_typ_id : number;
    begleitfrage_typ: string;
    frage : string;
    antwort : string;
    antwort_id : number;
    antwort_typ_id : number;

    constructor(){
        this.begleitfrage_id=0;
        this.einrichtungs_id=0;
        this.begleitfrage_typ_id=0;
        this.begleitfrage_typ=""
        this.frage ="";
        this.antwort="";   
        this.antwort_id=0;
        this.antwort_typ_id=0;
    }
}