import { Component, OnInit, Input, EventEmitter,ViewChild } from '@angular/core';
import { Restabfalltonne } from '../_models/index';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DataService } from '../_services/data.service'
import { Subscription } from 'rxjs';
import { PruefService } from '../_services/pruefservice.service';

import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-restabfall',
  templateUrl: './restabfall.component.html',
  styleUrls: ['./restabfall.component.css']
})
export class RestabfallComponent implements OnInit {
  //Eventlistener zum Speichern: die Komponente hört drauf
  @Input() saveRestabfalltonnen : EventEmitter<void>;
  @Input() pruefeEingabe : EventEmitter<void>;
  @ViewChild('abfallmenge', { static: true }) _SlideToggle_abfallmengeBekannt: MatSlideToggle;
  abfall_in_kg_pro_jahr : number;
  abfallmenge_in_kg : FormControl;
  AbfallmengeProJahrbekannt : boolean;
  counter:number;
  counterString:string;
  Leerungsintervalle: [{ Text:string; AnzahlProJahr:number}];
  matcher: ErrorStateMatcher;
  BHKWRohstoff: string;
  umweltcheckSubscription:Subscription;
  pullcounter : number

  RestabfalltonneFormGroup = new FormGroup({
    RestabfalltonnenArray: new FormArray([
      new FormControl('',[Validators.required,Validators.min(1)]), //"Volumen_in_litern"
      new FormControl('',[Validators.required]), //"Leerungen_pro_jahr"      
    ]),
  });
  get RestabfalltonnenArray() {
    return this.RestabfalltonneFormGroup.get('RestabfalltonnenArray') as FormArray;
  }
  addRestabfalltonneFormGroups() {
    if(this.RestabfalltonnenArray.length<=10){
      this.counter++;
      this.counterString=String(this.counter);
      this.RestabfalltonnenArray.push(new FormControl('',[Validators.required]));
      this.RestabfalltonnenArray.push(new FormControl('',[Validators.required]));
    }
  }
  addRestabfalltonneFormControlWithValue(AnzahlDerLeerungen :number,Volumen :number) {
    if(this.RestabfalltonnenArray.length<=10){
      this.RestabfalltonnenArray.push(new FormControl(Volumen,[Validators.required]));
      this.RestabfalltonnenArray.push(new FormControl(AnzahlDerLeerungen,[Validators.required]));
    }
  }
  removeRestabfalltonneFormGroups(i:number){
   if(this.RestabfalltonnenArray.length>0){     
      this.RestabfalltonnenArray.removeAt(this.RestabfalltonnenArray.length-1);
      this.RestabfalltonnenArray.removeAt(this.RestabfalltonnenArray.length-1); 
    }  
  }
  constructor(private Dataservice : DataService, private Pruefservice : PruefService) { 
    this.abfallmenge_in_kg=new FormControl('',[Validators.required])
    this.BHKWRohstoff=""
    this.counter=1;
    this.counterString='0';    
    //this.Leerungsintervalle=[{Text:"täglich",AnzahlProJahr: 365}]    
    this.Leerungsintervalle=[{Text:"2 x in der Woche", AnzahlProJahr: 104}]   
    //this.Leerungsintervalle.push({Text:"2 x in der Woche",AnzahlProJahr: 104})
    this.Leerungsintervalle.push({Text:"wöchentlich",AnzahlProJahr: 52})
   /* this.Leerungsintervalle.push({Text:"3 x in der Woche",AnzahlProJahr: 156})
    this.Leerungsintervalle.push({Text:"4 x in der Woche",AnzahlProJahr: 208})
    this.Leerungsintervalle.push({Text:"5 x in der Woche",AnzahlProJahr: 73})
    this.Leerungsintervalle.push({Text:"6 x in der Woche",AnzahlProJahr: 312})*/
    this.Leerungsintervalle.push({Text:"vierzehntäglich",AnzahlProJahr: 26})
   // this.Leerungsintervalle.push({Text:"alle drei Wochen",AnzahlProJahr: 17.33})
    this.Leerungsintervalle.push({Text:"monatlich",AnzahlProJahr: 12})
   /* this.Leerungsintervalle.push({Text:"11 x im Jahr",AnzahlProJahr: 11})
    this.Leerungsintervalle.push({Text:"10 x im Jahr",AnzahlProJahr: 10})
    this.Leerungsintervalle.push({Text:"9 x im Jahr",AnzahlProJahr: 9})
    this.Leerungsintervalle.push({Text:"8 x im Jahr",AnzahlProJahr: 8})
    this.Leerungsintervalle.push({Text:"7 x im Jahr",AnzahlProJahr: 7})
    this.Leerungsintervalle.push({Text:"6 x im Jahr",AnzahlProJahr: 6})
    this.Leerungsintervalle.push({Text:"5 x im Jahr",AnzahlProJahr: 5})
    this.Leerungsintervalle.push({Text:"4 x im Jahr",AnzahlProJahr: 4})
    this.Leerungsintervalle.push({Text:"3 x im Jahr",AnzahlProJahr: 3})
    this.Leerungsintervalle.push({Text:"2 x im Jahr",AnzahlProJahr: 2})*/
    this.Leerungsintervalle.push({Text:"1 x im Jahr",AnzahlProJahr: 1})

    this.matcher = new ErrorStateMatcher();
    this.pullcounter=0
  }
  ngOnInit() {
    this.Dataservice.UmweltchecksBhobj.subscribe(
        //Problem: wird einmal aufgerufnen beim Konstruktor und einmal, wenn die Datengeupdated werden.
        (Umweltchecks) => {console.log("RestabfallOnInit:",Umweltchecks); 
        if(Umweltchecks.Umweltchecks){
          if(Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Restabfalltonnen.length>0||Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.abfall_in_kg_pro_jahr>0){
            this.pullcounter++
            if(this.pullcounter==1){
              this.pullDatainRestabfalltonnenInRestabfallComponente() 
            }
          }
        }
      },
      (error) => {console.log("ERROR in Restabfallcomponente.", error)},
    )

    //Listen to saveRestabfalltonnen-Event vom Parentcomponent: eingabe.component => SAVE local Data!
    this.saveRestabfalltonnen.subscribe(
      ()=>{
        console.log("Restabfalltonnen: saveRestabfalltonnen")
        //lokal: schreibe die Werte in die Tonnen, dann in den Dataservice -
        this.saveAbfalltonnen()
        //absenden ans Backend! 
        this.Dataservice.saveRestabfalltonnen()},
      err=>{console.log(err)},
      complete=>{console.log("Restabfalltonnen erfolgereich vom Frontent versendet")}
    )
    this.pruefeEingabe.subscribe(()=>{
      setTimeout(()=>{          
      this.pruefeEingabeStromRestabfallKomponente()
      },0)
    })
  }
  //Abspeichern der Tonnen im Dataservice:
  saveAbfalltonnen(){
    //wenn die Menge bekannt ist, speicher die Menge, sonst die Tonnen
    //Dann die Einrichtung
    if(this._SlideToggle_abfallmengeBekannt.checked){
        this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.abfall_in_kg_pro_jahr=Number(this.abfallmenge_in_kg.value)
        this.Dataservice.deleteRestabfalltonnenInDS()    

        this.Dataservice.UmweltchecksBhobj.next(this.Dataservice.Umweltchecks)
        this.Dataservice.saveEinrichtung()
    }else{
    //setze den AbfallWert auf 0, falls da vorher was stand:
      this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.abfall_in_kg_pro_jahr=0
      this.Dataservice.UmweltchecksBhobj.next(this.Dataservice.Umweltchecks)
      this.Dataservice.saveEinrichtung()
    
    //es gibt manuelle Tonnen:  
      //Plan:
      //1. alle löschen:
      this.Dataservice.deleteRestabfalltonnenInDS()
      //2. jeden Datensatz zu einer Tonne bauen
      var volumen=0
      var AnzahlDerLeerungen=0
      var counter=0
      console.log("Restabfalltonnen: saveAbfalltonnen Controls:",this.RestabfalltonnenArray.controls)
      for (let control of this.RestabfalltonnenArray.controls){
        //1.Volumen:
        if(counter %2 == 0){
          var newRestabfalltonne=new Restabfalltonne()
          volumen=control.value
        }
        //2. Leerungen:
        if(counter %2 == 1){
          AnzahlDerLeerungen=control.value          
          newRestabfalltonne.einrichtungs_id=0
          newRestabfalltonne.einrichtungs_id=this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].einrichtungs_id
          newRestabfalltonne.volumen_in_litern = Number(volumen)
          newRestabfalltonne.leerungen_pro_jahr = Number(AnzahlDerLeerungen)
          console.log("newRestabfalltonne:",newRestabfalltonne)
          //3. Tonnen puschen
          this.Dataservice.addRestabfalltonne(newRestabfalltonne)              
          }
          counter++    
        }
        this.Dataservice.UmweltchecksBhobj.next(this.Dataservice.Umweltchecks)  
    }
  }
  pullDatainRestabfalltonnenInRestabfallComponente(){
    //For Each Restabfalltonne, -> add() und fülle die Daten rein ;-)
    if(this.Dataservice.Umweltchecks.Umweltchecks.length>0){
     var counter=0; 
      console.log("RestabfalltonnenArray",this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Restabfalltonnen)
      console.log("RestabfalltonnenControls:",this.RestabfalltonnenArray.controls)
      
      for (let tonne of this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.Restabfalltonnen){
        console.log("CountervariableRestabfalltonnen:",counter)
        //FEHLER: ich verstehe nicht, warum hier tonne nicht definiert sein kann?!
        //es gibt keine "leeren" Tonnen im Array und der counter zählt auch nicht drüber
        if(tonne){  
        //lege ne Neue Tonne an:
        if(counter>1){
          this.addRestabfalltonneFormControlWithValue(tonne.volumen_in_litern,tonne.leerungen_pro_jahr)
        }          
          this.RestabfalltonnenArray.controls[counter].setValue(tonne.volumen_in_litern)
          this.RestabfalltonnenArray.controls[counter+1].setValue(tonne.leerungen_pro_jahr)          
        }
        counter=counter+2;
      }
      //Abfalltoggle betätigen:
      if(this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.abfall_in_kg_pro_jahr>0){
        this._SlideToggle_abfallmengeBekannt.toggle()
        setTimeout(()=>{
          console.log("Restabfall: Setze abfall_in_kg_pro_jahr",this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.abfall_in_kg_pro_jahr)
          this.abfall_in_kg_pro_jahr=this.Dataservice.Umweltchecks.Umweltchecks[this.Dataservice.NummerUmweltcheck].Einrichtung.abfall_in_kg_pro_jahr
          this.abfallmenge_in_kg.setValue(Number(this.abfall_in_kg_pro_jahr))
        },250)
      }
    }   
  }
  pruefeEingabeStromRestabfallKomponente(){ 
    //jede Heizung hat nur ein Feld
    console.log("pruef pruefeEingabeStromRestabfallKomponente")
    //Toggle getoggled?
    if(this._SlideToggle_abfallmengeBekannt.checked){
      if(this.abfallmenge_in_kg.value==""||this.abfall_in_kg_pro_jahr<1){
        this.Pruefservice.pushNameErrorArray("Abfallmenge in kg pro Jahr ist bekannt: Abfallmenge muss > 0 sein",this.RestabfalltonneFormGroup.errors)
      }
    }else{
    //toggle nicht gechecked
    //gehe die Tonnen durch
    var i :number = 1
      //Restabfalltonne hat zwei Felder pro Tonne
      for (let control of this.RestabfalltonnenArray.controls){
        //immer die ungerade Nehmen: (wir verzichten auf eine Fehler mehr)
        if(control.invalid){
          if(i%2==1) {  //3
            this.Pruefservice.pushNameErrorArray("Restabfalltonne "+ String((i+1)/2) +": Volumen in Litern",control.errors)
          }
          if(i%2==0){  //5
            this.Pruefservice.pushNameErrorArray("Restabfalltonne "+ String((i+1)/2-1) +": Leerungsintervall",control.errors)
          }
        }
        i++
      }
    }  
    this.Pruefservice.pushInvalidFormgroup("Restabfälle",this.RestabfalltonneFormGroup)    
  }
}
